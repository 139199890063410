import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    rootContainer: {
      padding: 15,
      minWidth: theme.spacing(45),
    },
    titleText: {
      color: palette.primary.trellisBlue,
    },
  });
};

export default makeStyles(theme => styles(theme));
