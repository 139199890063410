// @ts-nocheck
import { Reducer } from 'redux';
import { ViewAction } from 'actions/action-view';
import { IViewState } from 'interfaces/redux/IViewState';
import { INoteTagForm } from 'interfaces/redux/INotes';
import {
  SCREEN_RESIZE,
  TOGGLE_LEFT_DRAWER,
  TOGGLE_FAB_PANEL,
  SET_ACTIVE_SIDEBAR,
  TOGGLE_PHONE_PANEL,
  TOGGLE_ONGOING_PHONE_CALL,
  SET_PHONE_NOTE_TAGS,
} from '../constants/index';

const initialState: IViewState = {
  sidebar: '',
  fabPanelOpen: false,
  phonePanelOpen: false,
  ongoingPhoneCall: false,
  leftDrawer: false,
  screenWidth: 0,
  initialUrl: '',
  phoneNoteTags: null,
};

const viewReducer: Reducer<IViewState, ViewAction> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_SIDEBAR: {
      return {
        ...state,
        sidebar: payload as string,
      };
    }
    case SCREEN_RESIZE: {
      const keepLeftDrawerClosed = (payload as number) >= 1280;
      return {
        ...state,
        leftDrawer: keepLeftDrawerClosed ? false : state.leftDrawer,
        screenWidth: payload as number,
      };
    }
    case TOGGLE_LEFT_DRAWER: {
      let newState = !!payload;
      if (payload === undefined) {
        newState = !state.leftDrawer;
      }
      return { ...state, leftDrawer: newState };
    }
    case TOGGLE_FAB_PANEL: {
      let newState = !!payload;
      if (payload === undefined) {
        newState = !state.fabPanelOpen;
      }
      return { ...state, fabPanelOpen: newState };
    }
    case TOGGLE_PHONE_PANEL: {
      let newState = !!payload;
      if (payload === undefined) {
        newState = !state.phonePanelOpen;
      }
      return { ...state, phonePanelOpen: newState };
    }
    case TOGGLE_ONGOING_PHONE_CALL: {
      let newState = !!payload;
      if (payload === undefined) {
        newState = !state.ongoingPhoneCall;
      }
      return { ...state, ongoingPhoneCall: newState };
    }
    case SET_PHONE_NOTE_TAGS: {
      return { ...state, phoneNoteTags: payload as INoteTagForm | null };
    }
    default:
      return state;
  }
};

export default viewReducer;
