import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import moment from 'moment';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { isEmpty, isEqual } from 'lodash';
import VerticalTabs from 'components/vertical-tabs';
import { Button, Divider, Grid, Typography, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { connect, useDispatch, useSelector } from 'react-redux';
import { convertToArborDate, datetimeFormat } from 'models/time/arbor-date';
import {
  COUNSELING_METHODS,
  CSL,
  DAYS,
  DC,
  FREQUENCY,
  INT,
  ONCOLOGY_THERAPEUTIC_CLASS,
} from 'constants/index';
import { CounselingMethod, CounselingTaskType, UserRoles } from 'constants/enums';
import { CounselingStatus } from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { CounselingTaskService } from 'services/utils/counseling-task-service';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import {
  Field,
  FormErrors,
  change,
  formValueSelector,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
} from 'redux-form';
import { ICounselingProtocol } from 'interfaces/counseling/ICounselingProtocol';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IDataCollectTask } from 'interfaces/redux/task-types/IDataCollectTask';
import { IServiceGroup } from 'interfaces/redux/IServiceGroup';
import { IState } from 'interfaces/redux/IState';
import { ITask } from 'interfaces/redux/ITasks';
import { ITaskCounts } from 'interfaces/redux/ITaskCounts';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { ReduxFormUtil } from 'utils/redux-form-util';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderReactRadioGroup } from 'components/form/field/redux-field';
import { required, validateDateIsBefore } from 'components/form/validation/validation';
import { TaskCounselingClient } from 'clients/task-counseling-client';
import { TaskId, TherapyId } from 'interfaces/RecordTypes';
import { TherapyUtil } from 'utils/therapy-util';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  clearSelectedTasks,
  editCounseling,
  editTasks,
  fetchTask,
  saveCounselingDraft,
  updateTaskProperties,
} from 'actions/action-tasks';
import {
  CounselingProtocolService,
  DEFAULT_SOAP_ORDER,
} from 'services/utils/counseling-protocol-service';
import { IAdherenceData } from 'interfaces/redux/IAdherenceData';
import { fetchPatientAdherenceData, fetchPatientArchivedTasks } from 'actions/action-patient';
import { StatusCodes } from 'http-status-codes';
import {
  useLatestFdcs,
  useLatestDur,
  usePatient,
  usePatientShortenTasks,
  useTasksTherapies,
  usePatientSubjectiveText,
  usePatientReviewDcs,
  useDocObjectiveDcs,
  getRecentDurFromTasks,
} from './hooks';
import { Timezone } from '../../../../../models/time/timezone';
import { Utils } from './documentation/utils';
import { styles } from './styles/counseling.styles';
import { logger } from '../../../../../winston-logger';
import { ISelectableLab } from './documentation/components/add-lab-values-modal';
import { ICounselingSnapshot } from './interfaces/ICounselingSnapshot';
import { ICounselingDraft } from './interfaces/ICounselingDraft';
import { ICounselingAddendum } from './interfaces/ICounselingAddendum';
import { getSideEffects } from './utils/get-side-effects';
import { getPatientObjectiveText } from './utils/get-patient-objective-text';
import { ChartReview } from './chart-review/chart-review';
import { AllFormFields, IAdministrationStatus, IFormFields, IProps } from './interfaces/IProps';
import FollowUp from './follow-up/follow-up';
import EducationAndCounseling from './education-and-counseling/education-and-counseling';
import Documentation from './documentation';
import { PatientReview, patientReviewHasReviewedDur } from '../counseling-transition-utils';
import { InterventionType } from './education-and-counseling/side-effects/interfaces/InterventionType';
import { noteTagTypes } from '../../../../../constants/lists';
import { getTherapyStatusById } from '../../../../../services/utils/therapy-service';
import EditAdministration, {
  handleFormName,
} from '../../../../patient/therapy/edit-therapy-administration';
import { editTherapy } from '../../../../../actions/action-therapies';
import { IDurSectionData } from './chart-review/interfaces/IDurSection';

const protocolService = new CounselingProtocolService();
const taskService = new CounselingTaskService();

const tabInvalidMessage = 'This section needs to be reviewed.';

const nameOfFormFields = nameOfFactory<AllFormFields>();

const fieldNamePerTask = (field: keyof AllFormFields, task: ITask) =>
  ReduxFormUtil.getFieldNamePerTask(field, task);

const REQUIRED_PATIENT_REVIEW_DATA_COLLECTS = 2;
const REQUIRED_DOC_OBJECTIVE_DATA_COLLECTS = 3;
const NEXT_ONGOING_CHECKIN_DEFAULT_WEEKS = 2;

/**
 * TODO: replace this 3 with a value from an enumeration, but that would mean updating
 * constants to typescript and introducing an enum for the values which seems
 * like a lot of changes for just 1 number :(
 * Or I have to write a find function which also isn't great.
 */
const chartReviewMethods = [CounselingMethod.ChartReview];

// Contains a tab index along with all the fields that we want to validate for that tab
const fieldsToValidatePerTab: Record<number, (keyof AllFormFields)[]> = {
  2: [
    'counselingCompletedDate',
    'nextAssessmentDate',
    'ongoingCounselingNeeded',
    'ongoingCheckinNeeded',
    'nextCheckinDate',
  ],
};

// Props from the redux state
interface IStateProps {
  therapy: ITherapy;
  taskCounts: ITaskCounts;
  initialValues: Partial<AllFormFields>;
  formValues: Partial<AllFormFields>;
  formErrors: FormErrors<AllFormFields>;
  serviceGroups: IServiceGroup[];
  currentCslType: number;
}

// Props from actions
interface IActionProps {
  editTasks: any;
  editCounseling: any;
  saveCounselingDraft: any;
  updateTaskProperties: any;
  notifyError: (msg: string) => void;
  notifySuccess: (msg: string) => void;
}

type Props = IStateProps & IProps & IFormFields & IActionProps;

// #region functions utils
function educationAndCounselingValidator(arr: any[], state?: boolean) {
  return (
    state !== undefined &&
    ((state && arr.length > 0) ||
      state === false ||
      (state !== undefined && typeof state !== 'boolean'))
  );
}
// #endregion

/**
 * Validates the follow up date is before one year ahead from latest CSL task completed date.
 */
const validateCslFollowupDate = (value: string, allValues: any, props: any): string | undefined => {
  const latestCslCompleteDate = props.tasks[0].latest_counseling_completed_dt;
  if (latestCslCompleteDate) {
    const customerTimezone = Timezone.getInstance().timezone;
    const oneYearAfterLatestCsl = moment(latestCslCompleteDate as Date)
      .tz(customerTimezone)
      .add(1, 'years')
      .startOf('day')
      .toDate();
    const tomorrow = moment(new Date()).tz(customerTimezone).add(1, 'days').startOf('day').toDate();
    // Take the max of oneYearAfterLatestCsl and tomorrow
    const dateMustBeBefore = new Date(Math.max(Number(oneYearAfterLatestCsl), Number(tomorrow)));
    return validateDateIsBefore(dateMustBeBefore)(value);
  }
  return undefined;
};

const Complete: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes, serviceGroups, therapy } = props;

  // #region component state setup
  const hasMounted = useRef<boolean>(false);
  const state = useTypedSelector(({ medications, therapies, allergies, links }) => ({
    medications,
    therapies,
    allergies,
    links,
  }));
  const allTasks = useTypedSelector(state => state.tasks.data);
  const allUsers = useTypedSelector(state => state.lookups.users);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [subformIsCompleted, setSubformIsCompleted] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [chartReview, setChartReview] = useState<boolean>(false);
  const [loadingTaskData, setLoadingTaskData] = useState<boolean>(false);
  const [loadingProtocolData, setLoadingProtocolData] = useState<boolean>(false);
  const [forceSaveDocumentationTrigger, setForceSaveDocumentationTrigger] =
    useState<boolean>(false);
  const [forceSaveFinished, setForceSaveFinished] = useState<boolean>(false);
  const [saveDraft, setSaveDraft] = useState<boolean>(false);
  const [tabValidStatuses, setTabValidStatuses] = useState<boolean[]>(
    props.viewOnly
      ? (Array.from({ length: 4 }).fill(true) as boolean[])
      : [false, false, false, false],
  );
  const [visitedTabs, setVisitedTabs] = useState<Set<number>>(new Set<number>());
  const [nextAssessmentMaxDates, setNextAssessmentValidation] = useState<
    Record<TaskId, moment.Moment>
  >({});
  const [formValues, setFormValues] = useState<Partial<AllFormFields>>({});
  const [selectedTaskIds, setSelectedTaskIds] = useState<Set<number>>(new Set());
  const [hideOngoingCounselingNeededQuestions, setHideOngoingCounselingNeededQuestions] = useState<
    Record<TaskId, boolean> | undefined
  >({});
  const adherenceData: IAdherenceData | undefined =
    useTypedSelector(state => state.patient.adherenceData) || undefined;
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const therapyAdministrationStatuses = useTypedSelector(
    storeState => storeState.therapyStatuses?.therapyAdministrationStatuses,
  );

  const dispatch = useDispatch();
  const setStoreFormValues = (form: string, field: string, value: any): void => {
    dispatch(change(form, field, value));
  };

  // #endregion component state setup
  const medicationGroups = useTypedSelector(storeState => storeState.medications.medicationGroups);

  const tagTypeId = noteTagTypes.find(tag => tag.label === 'Therapy')?.value;
  const administrationStatusId = useMemo(() => therapy.administration_status_id, [therapy]);
  const medication = useMemo(() => {
    return Array.isArray(medicationGroups)
      ? medicationGroups.find(m => m.id === therapy.medication_id)
      : null;
  }, [medicationGroups, therapy]);
  const therapyIds = useMemo(() => props.tasks.map(({ therapy_id }) => therapy_id), props.tasks);

  const isCounselingCompleted = !(
    props.tasks?.[0].status_id !== CounselingStatus.Completed && !props.requestReview
  );
  const adminStatus = useMemo(() => {
    return getTherapyStatusById(therapyAdministrationStatuses, administrationStatusId);
  }, [therapyAdministrationStatuses, administrationStatusId]);
  const formRef = React.createRef<HTMLElement>();

  // Get task with snapshot if there is at least one
  const snapshottedTask = props.tasks.find(
    task => task.readonly && task.status_id === CounselingStatus.Completed,
  );

  const isSnapshot = Boolean(snapshottedTask);

  // Get all tasks with draft
  let draftedTasks = props.tasks.filter(task => task.has_draft);
  let hasDrafts = Boolean(draftedTasks.length);

  const currentUser = useSelector((state: IState) => state.auth.currentUser);
  const canUserSaveCSL = () => {
    return currentUser.roles.some(x => x.name !== UserRoles.PharmacyIntern);
  };

  // #region pull redux state information
  const statePatientReview: PatientReview = {
    patient: usePatient(true),
    drugUtilizationReview: useLatestDur(),
    dataCollects: usePatientReviewDcs(REQUIRED_PATIENT_REVIEW_DATA_COLLECTS, props.tasks),
    fdcItems: useLatestFdcs(props.tasks),
    readOnly: isSnapshot,
    isCslDur: true,
    pdcTable: adherenceData,
  };

  const handleClearSelectedTasks = () => {
    dispatch(clearSelectedTasks());
  };

  const stateDocumentation = {
    subjective: usePatientSubjectiveText(props.tasks),
    objective: getPatientObjectiveText(props.tasks, state, {}),
  };

  const stateObjectiveDataCollects = useDocObjectiveDcs(
    REQUIRED_DOC_OBJECTIVE_DATA_COLLECTS,
    props.tasks,
  );

  const stateFollowUp = {
    shortenTasks: usePatientShortenTasks(),
    tasksTherapies: useTasksTherapies(props.tasks),
  };

  const stateSideEffects = getSideEffects(state, props.tasks);

  const [counselingSnapshot, setCounselingSnapshot] = useState<ICounselingSnapshot>({
    patientReview: statePatientReview,
    followUp: stateFollowUp,
    tasks: props.tasks,
    soapOrder: DEFAULT_SOAP_ORDER,
    protocolData: {},
    forms: {},
    documentation: stateDocumentation,
    sideEffects: stateSideEffects,
    objectiveDataCollects: stateObjectiveDataCollects,
    objectiveSelectedLabValues: [],
  });
  const [newAddendumContent, setNewAddendumContent] = useState<string | undefined>(undefined);
  const [addendumClearTrigger, setAddendumClearTrigger] = useState<undefined | Date>(undefined);
  const [counselingAddendums, setCounselingAddendums] = useState<ICounselingAddendum[]>([]);

  const getProtocolRequests = (
    tasks: ICounselingTask[],
    counselingMethod?: CounselingMethod,
  ): Promise<{ response: ICounselingProtocol; taskId: TaskId }>[] =>
    tasks.map(task =>
      protocolService
        .getByTaskId(task.id, task.counseling_type, counselingMethod)
        .then(response => {
          return {
            response,
            taskId: task.id,
          };
        }),
    );

  const getIsOnGoingScheduledRequests = (
    tasks: ICounselingTask[],
  ): Promise<{ isOngoingScheduled: boolean; taskId: TaskId }>[] =>
    tasks.map(task =>
      protocolService.getIsOngoingScheduled(task.id).then(isOngoingScheduled => {
        return {
          isOngoingScheduled,
          taskId: task.id,
        };
      }),
    );
  // #endregion pull redux state information

  // #region snapshot functions
  const parseSnapshotTaskDates = (snapshot: ICounselingSnapshot): ICounselingSnapshot => {
    return {
      ...snapshot,
      followUp: {
        ...snapshot.followUp,
        shortenTasks: snapshot.followUp.shortenTasks.map(shortenTask => ({
          ...shortenTask,
          nextDate: moment(shortenTask.nextDate).toDate(),
        })),
      },
    };
  };

  const fillFormDataFromSnapshot = (snapshot: ICounselingSnapshot) => {
    props.change(nameOfFormFields('serviceGroup'), snapshot.forms?.serviceGroup);

    props.change(
      nameOfFormFields('counselingCompletedDate'),
      moment.utc(snapshot.forms?.counselingCompletedDate),
    );

    props.change(nameOfFormFields('method'), snapshot.forms?.method);
    props.change(nameOfFormFields('sessionDuration'), snapshot.forms?.sessionDuration);

    props.change(
      nameOfFormFields('sideEffectManagement'),
      snapshot?.forms?.sideEffectManagement !== undefined
        ? snapshot?.forms?.sideEffectManagement
        : (snapshot?.sideEffects?.filter(({ type }) => type === InterventionType.SideEffect) || [])
            .length > 0,
    );

    props.change(
      nameOfFormFields('adherence'),
      snapshot?.forms?.adherence !== undefined
        ? snapshot?.forms?.adherence
        : (snapshot?.sideEffects?.filter(({ type }) => type === InterventionType.Adherence) || [])
            .length > 0,
    );

    snapshot.tasks.forEach(task => {
      props.change(
        fieldNamePerTask('ongoingCounselingNeeded', task),
        snapshot?.forms?.ongoingCounselingNeeded?.[task.id],
      );

      if (snapshot?.forms?.nextAssessmentDate?.[task.id] !== null) {
        props.change(
          fieldNamePerTask('nextAssessmentDate', task as ITask),
          moment.utc(snapshot?.forms?.nextAssessmentDate?.[task.id]),
        );
      }

      props.change(
        fieldNamePerTask('ongoingCheckinNeeded', task),
        snapshot?.forms?.ongoingCheckinNeeded?.[task.id],
      );

      if (snapshot?.forms?.nextCheckinDate?.[task.id] !== null) {
        props.change(
          fieldNamePerTask('nextCheckinDate', task as ITask),
          moment.utc(snapshot?.forms?.nextCheckinDate?.[task.id]),
        );
      }
    });
  };
  // #endregion snapshot function

  // #region draft functions
  const getValueIfAreAllEqual = (elements: any[]) => {
    const valuesSet = new Set(elements);
    if (valuesSet.size === 1) {
      return valuesSet.values().next().value;
    }
    return null;
  };

  const fillFormSharedFieldsFromDrafts = (taskDrafts: Partial<ICounselingDraft>[]): void => {
    // If not all task have snapshots we just keep shared fields clean, not using snapshot values
    if (props.tasks.length === taskDrafts.length) {
      const followUpDate = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues.followUpDate),
      );

      const serviceGroup = getValueIfAreAllEqual(
        // need to stringify to check equality since it's an object
        taskDrafts.map(taskDraft => JSON.stringify(taskDraft.draft?.formValues?.serviceGroup)),
      );

      const method = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.method),
      );

      const sessionDuration = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.sessionDuration),
      );

      const counselingCompletedDate = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.counselingCompletedDate),
      );

      const sideEffectManagement = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.sideEffectManagement),
      );

      const adherence = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.adherence),
      );

      const administrationStatus = getValueIfAreAllEqual(
        taskDrafts.map(taskDraft => taskDraft.draft?.formValues?.administrationStatus),
      );

      props.change(
        nameOfFormFields('followUpDate'),
        followUpDate ? moment.utc(followUpDate) : null,
      );

      props.change(
        nameOfFormFields('serviceGroup'),
        serviceGroup ? JSON.parse(serviceGroup) : null,
      );

      props.change(nameOfFormFields('method'), method);

      props.change(nameOfFormFields('sessionDuration'), sessionDuration);
      props.change(
        nameOfFormFields('counselingCompletedDate'),
        counselingCompletedDate ? moment.utc(counselingCompletedDate) : null,
      );

      props.change(nameOfFormFields('sideEffectManagement'), sideEffectManagement);
      props.change(nameOfFormFields('adherence'), adherence);

      for (const [key, value] of Object.entries(administrationStatus || {})) {
        setStoreFormValues(handleFormName(), key, value);
      }
    }
  };

  const fillFormPerTherapyFieldsFromDraft = (taskDrafts: Partial<ICounselingDraft>[]): void => {
    /**
     * TODO:
     *  taskDrafts is not actually a singular ICounselingDraft object since when it's a draft
     *  the form fields are the actual values for a given task, rather than a dictionary.
     */
    taskDrafts.forEach(taskDraft => {
      const task = props.tasks.find(task => taskDraft.task_id === task.id);
      if (task) {
        props.change(
          fieldNamePerTask('ongoingCounselingNeeded', task),
          taskDraft.draft?.formValues.ongoingCounselingNeeded || 0,
        );

        if (taskDraft.draft?.formValues.nextAssessmentDate !== null) {
          props.change(
            fieldNamePerTask('nextAssessmentDate', task as ITask),
            moment.utc(taskDraft.draft?.formValues.nextAssessmentDate),
          );
        }
        props.change(
          fieldNamePerTask('ongoingCheckinNeeded', task),
          taskDraft.draft?.formValues.ongoingCheckinNeeded || 0,
        );

        if (taskDraft.draft?.formValues.nextCheckinDate !== null) {
          props.change(
            fieldNamePerTask('nextCheckinDate', task as ITask),
            moment.utc(taskDraft.draft?.formValues.nextCheckinDate),
          );
        }
      }
      if (taskDraft.draft?.formValues.administrationStatus !== undefined) {
        for (const [key, value] of Object.entries(
          taskDraft?.draft?.formValues?.administrationStatus,
        )) {
          setStoreFormValues(handleFormName(), key, value);
        }
      }
    });
  };

  const getMergedDocumentationFromDrafts = (
    taskDrafts: Partial<ICounselingDraft>[],
    objectiveFromState: { content?: string; secondaryContent?: string; initialContent?: string },
  ): {
    objective: { content?: string; secondaryContent?: string; initialContent?: string };
    subjective: { content?: string; secondaryContent?: string; initialContent?: string };
  } => {
    const draftsMergedDocs = {
      subjective: {
        content: '',
      },
      objective: {
        content: objectiveFromState.content,
        secondaryContent: '',
      },
    };

    // If there are more than 1 task drafts add as title for each doc the therapy name when concat
    const areMultipleDrafts = taskDrafts.length > 1;

    taskDrafts.forEach(taskDraft => {
      const counselingTask = props.tasks?.find(task => task.id === taskDraft.task_id);
      const separator = '</br>';
      const titleSeparator = areMultipleDrafts ? separator.repeat(2) : '';
      const sectionSeparator = areMultipleDrafts ? separator.repeat(3) : '';

      const therapy =
        counselingTask?.therapy_id && stateFollowUp.tasksTherapies[counselingTask.therapy_id];

      const title =
        therapy && areMultipleDrafts
          ? `<strong>${TherapyUtil.getParagraphFormat(therapy)}</strong>${titleSeparator}`
          : '';

      // Subjective -> content
      draftsMergedDocs.subjective.content =
        Utils.mergeContent(
          title,
          draftsMergedDocs.subjective.content,
          taskDraft.draft?.documentation?.subjective?.content,
          sectionSeparator,
        ) || '';

      // Objective -> secondaryContent
      draftsMergedDocs.objective.secondaryContent =
        Utils.mergeContent(
          title,
          draftsMergedDocs.objective.secondaryContent,
          taskDraft.draft?.documentation?.objective?.secondaryContent,
          sectionSeparator,
        ) || '';
    });

    return {
      subjective: {
        content: Utils.wrapWithPtags(draftsMergedDocs.subjective.content),
      },
      objective: {
        content: Utils.wrapWithPtags(draftsMergedDocs.objective.content),
        secondaryContent: Utils.wrapWithPtags(draftsMergedDocs.objective.secondaryContent),
      },
    };
  };

  const mapTaskFollowUpDate = (date: Record<number, moment.Moment>) => {
    const [value] = Object.entries(date).map((k, v) => ({ [k[0]]: k[1] }));
    return value;
  };

  const getMergedObjectiveSelectedLabValues = (
    taskDrafts: Partial<ICounselingDraft>[],
  ): ISelectableLab[] => {
    return taskDrafts.reduce((accum: ISelectableLab[], taskDraft: Partial<ICounselingDraft>) => {
      taskDraft.draft?.objectiveSelectedLabValues.forEach(selectedValue => {
        const objectiveDcTask = stateObjectiveDataCollects?.find(
          dataCollect => selectedValue?.taskId === dataCollect?.taskId,
        );

        const matchingLab = objectiveDcTask?.labs?.find(
          lab => lab?.dataTypeId === selectedValue?.dataTypeId,
        );

        if (objectiveDcTask && matchingLab) {
          accum.push({
            taskId: objectiveDcTask.taskId,
            selected: true,
            ...matchingLab,
          });
        }
      });
      return accum;
    }, []);
  };
  // #endregion draft

  /**
   *
   * @param tasks Tasks for this form
   * @param tasksHaveChanged If we should make all the API calls to update tasks from the DB. Typically
   * used when something about tasks change (trigger by some state update) but the list of tasks
   * don't actually change
   */
  const onTasksChange = async (tasks: ICounselingTask[], tasksHaveChanged = true) => {
    const counselingProtocolResponses: Record<TherapyId, ICounselingProtocol> = {};
    const nextAssessmentDates: Record<TaskId, moment.Moment> = {};

    let soapOrderResponse: string[] = [];
    let protocolResponses: {
      response: ICounselingProtocol;
      taskId: TaskId;
    }[] = [];
    let ongoingScheduledResponse: { isOngoingScheduled: boolean; taskId: TaskId }[] = [];

    // There's at least 1 task with snapshot so replace all redux content with that snapshot data
    if (snapshottedTask?.id && tasksHaveChanged) {
      const snapshotApiResponse = await taskService.getSnapshot(snapshottedTask.id);
      fillFormDataFromSnapshot(snapshotApiResponse.counseling_snapshot);
      const parsedSnapshot = parseSnapshotTaskDates(snapshotApiResponse.counseling_snapshot);

      setCounselingSnapshot(parsedSnapshot);
      setCounselingAddendums(snapshotApiResponse.addendums);
      return;
      // If there's no snapshot and CSL tasks were selected or unselected (tasksHaveChanged).
    }
    if (tasksHaveChanged) {
      const isChartReview = isSelectedMethodChartReview(props.formValues.method);
      setChartReview(isChartReview);

      setLoadingTaskData(true);
      soapOrderResponse = await protocolService.getSoapOrder();
      protocolResponses = await Promise.all(getProtocolRequests(tasks, props.formValues.method));
      ongoingScheduledResponse = await Promise.all(getIsOnGoingScheduledRequests(tasks));
      setLoadingTaskData(false);

      props.change(nameOfFormFields('ongoingCounselingNeeded'), {
        ...props.initialValues.ongoingCounselingNeeded,
        ...props.formValues.ongoingCounselingNeeded,
      });

      const serviceGroup = serviceGroups.find(
        serviceGroup => serviceGroup.id === props.tasks[0].service_group_id,
      );

      props.change(
        nameOfFormFields('serviceGroup'),
        serviceGroup
          ? {
              value: serviceGroup.id,
              label: serviceGroup.display_name,
            }
          : null,
      );

      protocolResponses
        .filter(protocolResponse => protocolResponse.response != null)
        .forEach(protocolResponse => {
          counselingProtocolResponses[protocolResponse.taskId] = protocolResponse.response;

          // Set the next assessment date to the furthest date out ...
          const { value, unit } = protocolResponse.response.ongoing;
          const numDays = value * unit;
          const maxNextDate =
            props.tasks &&
            props.tasks.length &&
            [CounselingTaskType.Initial, CounselingTaskType.Ongoing].includes(
              props.tasks[0].counseling_type,
            )
              ? moment.utc().add(DAYS.ELEVEN_MONTHS, 'days').prevBusinessDay().startOf('day')
              : moment.utc().add(numDays, 'days').prevBusinessDay().startOf('day');

          let defaultNextDate = maxNextDate
            .clone()
            .subtract(FREQUENCY.MONTH, 'days')
            .prevBusinessDay()
            .startOf('day');

          if (props.therapy?.trellis_therapeutic_class_id === ONCOLOGY_THERAPEUTIC_CLASS) {
            defaultNextDate = moment
              .utc()
              .add(DAYS.FIVE_MONTH, 'days')
              .prevBusinessDay()
              .startOf('day');
          } else if (
            props.tasks &&
            props.tasks.length &&
            [CounselingTaskType.Initial, CounselingTaskType.Ongoing].includes(
              props.tasks[0].counseling_type,
            )
          ) {
            defaultNextDate = moment
              .utc()
              .add(DAYS.ELEVEN_MONTHS, 'days')
              .prevBusinessDay()
              .startOf('day');
          }
          props.change(
            fieldNamePerTask('nextAssessmentDate', { id: protocolResponse.taskId } as ITask),
            defaultNextDate,
          );
          nextAssessmentDates[protocolResponse.taskId] = maxNextDate;
        });

      const hideOngoingQuestions: Record<TaskId, boolean> = {};

      ongoingScheduledResponse.forEach(scheduleResponse => {
        // eslint-disable-next-line eqeqeq
        const currentTask = props.tasks.find(task => task.id == scheduleResponse.taskId);
        const nextCheckinDate = moment
          .utc()
          .add(NEXT_ONGOING_CHECKIN_DEFAULT_WEEKS, 'weeks')
          .prevBusinessDay()
          .startOf('day');

        props.change(
          fieldNamePerTask('nextCheckinDate', { id: scheduleResponse.taskId } as ITask),
          nextCheckinDate,
        );
        /**
         * Conditionally show "ongoing counseling needed" question if there is not already
         * ongoing counseling needed for that therapy AND the current CSL task is check-in or if task
         * counseling type is initial or reassessment
         */
        let shouldHideOngoingQuestions = true;

        if (
          currentTask?.counseling_type === CounselingTaskType.Initial ||
          currentTask?.counseling_type === CounselingTaskType.Ongoing ||
          (currentTask?.counseling_type === CounselingTaskType.CheckIn &&
            !scheduleResponse.isOngoingScheduled)
        ) {
          shouldHideOngoingQuestions = false;
        }

        hideOngoingQuestions[scheduleResponse.taskId] = shouldHideOngoingQuestions;
      });
      setHideOngoingCounselingNeededQuestions(hideOngoingQuestions);
    }

    // This doesn't happens if there is a snapshot, see return statement on snapshot code (first if statement)
    let draftsMergedDocs = null;
    let draftDocWasEdited = false;
    let draftsMergedLabValues: ISelectableLab[] = [];

    if (hasDrafts) {
      const drafts = await Promise.all(
        draftedTasks.map(async (taskWithDraft, idx) => {
          try {
            const result = await taskService.getDraft(taskWithDraft.id);
            const { draft, task_id } = result;
            const output: Partial<ICounselingDraft> = {
              task_id,
            };
            if (draft) {
              output.draft = {
                documentation: {
                  dotPhrase: draft?.documentation?.dotPhrase,
                  objective: draft?.documentation?.objective,
                  subjective: {
                    content: draft?.documentation?.subjective?.content?.replace(/\n/gi, ''),
                    initialContent: draft?.documentation?.subjective?.initialContent?.replace(
                      /\n/gi,
                      '',
                    ),
                    secondaryContent: draft?.documentation?.subjective?.secondaryContent?.replace(
                      /\n/gi,
                      '',
                    ),
                  },
                },
                formValues: draft?.formValues,
                objectiveSelectedLabValues: draft?.objectiveSelectedLabValues,
                protocolData: draft?.protocolData,
              };
            }
            return output;
          } catch (err) {
            const { response } = err as any;
            if (response?.status === StatusCodes.NOT_FOUND) {
              // mark task as draft to avoid recursive call
              draftedTasks[idx].has_draft = false;
              draftedTasks = draftedTasks.filter(task => task.id === taskWithDraft.id);
              hasDrafts = Boolean(draftedTasks.length);
              dispatch(fetchTask(taskWithDraft.taskType, taskWithDraft.id));
            }
            throw err;
          }
        }),
      );

      fillFormSharedFieldsFromDrafts(drafts);
      fillFormPerTherapyFieldsFromDraft(drafts);

      draftsMergedDocs = getMergedDocumentationFromDrafts(drafts, stateDocumentation?.objective);
      draftsMergedLabValues = getMergedObjectiveSelectedLabValues(drafts);

      draftDocWasEdited =
        counselingSnapshot.documentation.subjective.content !==
          draftsMergedDocs.subjective.content ||
        counselingSnapshot.documentation.objective.secondaryContent !==
          draftsMergedDocs.objective.secondaryContent;

      protocolResponses.forEach(protocolResponse => {
        const draftResponse = drafts.find(draft => draft.task_id === protocolResponse.taskId);
        const draftDotPhrases = draftResponse?.draft?.documentation?.dotPhrase;
        const draftSupportiveMeds =
          draftResponse?.draft?.protocolData?.[protocolResponse.taskId]?.supportiveMeds || [];

        counselingProtocolResponses[protocolResponse.taskId] = {
          ...protocolResponse.response,
          // Overwriting dotPhrase docs from draft
          dotPhrase: draftDotPhrases || protocolResponse.response.dotPhrase,
          supportiveMeds:
            draftSupportiveMeds && Object.keys(draftSupportiveMeds).length > 0
              ? draftSupportiveMeds
              : protocolResponse.response.supportiveMeds,
        };
      });
    }

    // Merging all lab values, from draft and selected ones (if was edited and more labs were added without saving draft yet)
    const allSelectedLabValues = [
      ...draftsMergedLabValues,
      ...(counselingSnapshot.objectiveSelectedLabValues?.length
        ? counselingSnapshot.objectiveSelectedLabValues
        : []),
    ];

    setNextAssessmentValidation(nextAssessmentDates);

    let counselingSnapshotUpdate: Partial<ICounselingSnapshot> = {};

    if (tasksHaveChanged) {
      counselingSnapshotUpdate = {
        documentation: draftsMergedDocs || stateDocumentation,
        protocolData: counselingProtocolResponses,
        soapOrder: soapOrderResponse,
      };
    } else {
      const stateObjectiveDocumentation = {
        ...counselingSnapshot.documentation,
        objective: {
          content: stateDocumentation.objective.content,
          secondaryContent: counselingSnapshot.documentation.objective.secondaryContent,
        },
      };

      counselingSnapshotUpdate = {
        documentation: draftDocWasEdited
          ? stateObjectiveDocumentation
          : draftsMergedDocs || stateObjectiveDocumentation,
      };
    }

    if (!counselingSnapshotUpdate.documentation) {
      counselingSnapshotUpdate.documentation = {};
    }

    counselingSnapshotUpdate.documentation.objective = getPatientObjectiveText(
      props.tasks,
      state,
      counselingProtocolResponses,
    );

    setCounselingSnapshot({
      ...counselingSnapshot,
      ...counselingSnapshotUpdate,
      tasks,
      followUp: stateFollowUp,
      patientReview: statePatientReview,
      sideEffects: stateSideEffects,
      objectiveDataCollects: stateObjectiveDataCollects,
      objectiveSelectedLabValues: allSelectedLabValues,
    });
  };

  const allTasksCompleted = React.useMemo<boolean>(() => {
    return props.tasks.every(task => task.status_id === CounselingStatus.Completed);
  }, [props.tasks]);

  React.useEffect(() => {
    if (!adherenceData) {
      dispatch(fetchPatientAdherenceData(patientId));
    } else if (adherenceData && !counselingSnapshot.patientReview.pdcTable) {
      setCounselingSnapshot({
        ...counselingSnapshot,
        patientReview: {
          ...counselingSnapshot.patientReview,
          pdcTable: adherenceData,
        },
      });
    }
  }, [adherenceData, counselingSnapshot.patientReview.pdcTable]);

  React.useEffect(() => {
    const mostRecentDurTask = getRecentDurFromTasks(allTasks, allUsers);

    setCounselingSnapshot({
      ...counselingSnapshot,
      patientReview: {
        ...counselingSnapshot.patientReview,
        drugUtilizationReview: mostRecentDurTask as IDurSectionData,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTasks]);

  React.useEffect(() => {
    const sideEffects = getSideEffects(state, props.tasks);
    const subFormSideEffect = sideEffects.filter(
      sideEffect => sideEffect.type === InterventionType.SideEffect,
    );
    const subFormAdherence = sideEffects.filter(
      sideEffect => sideEffect.type === InterventionType.Adherence,
    );

    const hasInputSideEffect = subFormSideEffect.map(intTask => {
      return props.tasks.find(t => t.taskType === INT && t.id === intTask.interventionId);
    });
    const hasInputAdherence = subFormAdherence.map(intTask => {
      return props.tasks.find(t => t.taskType === INT && t.id === intTask.interventionId);
    });

    const isValid =
      educationAndCounselingValidator(hasInputSideEffect, props.formValues.sideEffectManagement) &&
      educationAndCounselingValidator(hasInputAdherence, props.formValues.adherence);

    setSubformIsCompleted(isValid);
  }, [props.tasks, props.formValues.sideEffectManagement, props.formValues.adherence]);

  React.useEffect(() => {
    const wasChartReview = chartReview;
    const isChartReview = isSelectedMethodChartReview(props.formValues.method);
    setChartReview(isChartReview);

    if ((!wasChartReview && isChartReview) || (wasChartReview && !isChartReview)) {
      setLoadingProtocolData(true);
      (async function getDocumentation() {
        const protocolResponses = await Promise.all(
          getProtocolRequests(props.tasks, props.formValues.method),
        );
        const results: Record<TaskId, ICounselingProtocol> = {};
        protocolResponses
          .filter(protocolResponse => protocolResponse != null)
          .forEach(protocolResponse => {
            results[protocolResponse.taskId] = protocolResponse.response;
          });
        setLoadingProtocolData(false);
        setCounselingSnapshot(prevState => {
          return {
            ...prevState,
            protocolData: results,
          };
        });
      })();
    }
  }, [props.formValues.method]);

  React.useEffect(() => {
    // does the state have all of the tasks ids?
    const allIdsSelected = props.tasks.every(x => selectedTaskIds.has(x.id));
    /**
     * is the length of the state different from the number of tasks?
     * Will tell us if the selected went from something like [3,1] to [1]
     */
    const sameNumberOfTasksSelected = selectedTaskIds.size === props.tasks.length;
    if (!allIdsSelected || !sameNumberOfTasksSelected) {
      setSelectedTaskIds(new Set([...props.tasks.map(x => x.id)]));
      onTasksChange(props.tasks, true);
    }
  }, [props.tasks]);

  React.useEffect(() => {
    if (saveDraft) {
      saveCounselingTaskDraft(formValues);
    } else if (hasMounted.current) {
      saveCounselingTask(formValues);
    } else {
      hasMounted.current = true;
    }
  }, [forceSaveFinished]);

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientArchivedTasks(patientId, false, [DC] as any));
    }
  }, [patientId]);

  useEffect(() => {
    if (
      isEmpty(stateObjectiveDataCollects) ||
      isEqual(stateObjectiveDataCollects, counselingSnapshot.objectiveDataCollects)
    ) {
      return;
    }
    setCounselingSnapshot({
      ...counselingSnapshot,
      objectiveDataCollects: stateObjectiveDataCollects,
    });
  }, [stateObjectiveDataCollects]);

  const isSelectedMethodChartReview = (currentValue: number | undefined): boolean => {
    return (currentValue && chartReviewMethods.includes(currentValue)) === true;
  };

  // #region save snpshot and draft functions
  const buildBundleTaskPayload = (
    formValues: Partial<AllFormFields>,
    snapshot: ICounselingSnapshot,
    editing: boolean,
  ) => {
    // @TODO - This fields are not present in the current mockup for completed counseling
    const orders: any[] = [];
    const notes: any[] = [];
    const documents: any[] = [];

    const formData = {
      ...formValues,
      serviceGroup: formValues.serviceGroup!.value,
      followUpDt: convertToArborDate(formValues.followUpDate!).getUtcDatetime(),
      method: formValues.method,
      sessionDuration: formValues.sessionDuration ? +formValues.sessionDuration : null,
      ongoingCounselingNeeded: formValues.ongoingCounselingNeeded,
      todayDate: convertToArborDate(formValues.counselingCompletedDate!, true).getUtcDatetime(),
    };

    const updatedSnapshot: ICounselingSnapshot = { ...snapshot, tasks: props.tasks };

    const bundleTasks = snapshot.tasks.map((task, index) => {
      let nextCounseling: string | null = null;
      const ongoingCounselingNeeded =
        (hideOngoingCounselingNeededQuestions &&
          Object.keys(hideOngoingCounselingNeededQuestions).length === 0) ||
        hideOngoingCounselingNeededQuestions?.[task.id] === false
          ? formData?.ongoingCounselingNeeded?.[task.id] ?? 0
          : 0;
      if (ongoingCounselingNeeded) {
        nextCounseling = convertToArborDate(
          formData?.nextAssessmentDate?.[task.id],
          false,
        ).getUtcDatetime();
      }

      const nextCheckin: string | null = convertToArborDate(
        formData?.nextCheckinDate?.[task.id],
      ).getUtcDatetime();

      // Convert counseling completed date to UTC and format to a string
      let completedDt: string | null =
        formData?.counselingCompletedDate?.utc()?.format(datetimeFormat) ?? null;

      let statusId = props.editing ? task.status_id : CounselingStatus.Completed;
      if (props.requestReview && !props.editing) {
        statusId = CounselingStatus.PharmacistReviewNeeded;
        completedDt = null;
      }

      const taskSnapshot = {
        ...updatedSnapshot,
        forms: {
          ...formValues,
          nextAssessmentDate: mapTaskFollowUpDate(formValues.nextAssessmentDate || {}),
          nextCheckinDate: mapTaskFollowUpDate(formValues.nextCheckinDate || {}),
        },
      };

      return {
        status_id: statusId,
        method: formData.method,
        ongoing_counseling_needed: ongoingCounselingNeeded,
        next_counseling_date: nextCounseling,
        checkin_counseling_needed: formData?.ongoingCheckinNeeded?.[task.id] ?? 0,
        next_checkin_date: nextCheckin,
        completed_dt: !props.editing ? completedDt : task.completed_dt,
        reason: task.reason,
        delivery: { id: task.id },
        id: task.id,
        patient_id: statePatientReview.patient.id,
        taskType: task.taskType,
        therapy_id: task.therapy_id,
        service_group_id: formData.serviceGroup,
        followup_dt: formData.followUpDt,
        old_status_id: task.status_id,
        old_method: task.method,
        old_duration_minutes: formData.sessionDuration ?? '00',
        old_reason: task.reason,
        old_id: task.id,
        old_taskType: task.taskType,
        old_therapy_id: task.therapy_id,
        old_service_group_id: task.service_group_id,
        old_followup_dt: task.followup_dt,
        // only setting snapshot on first task (reducing payload size) since it's the same for every task
        task_snapshot: index === 0 ? taskSnapshot : undefined,
      };
    });

    return {
      orders,
      notes,
      documents,
      tasks: bundleTasks,
      newAddendum: newAddendumContent,
    };
  };

  const triggerDocumentationSave = () => {
    setForceSaveDocumentationTrigger(!forceSaveDocumentationTrigger);
  };

  const triggerSaveDraft = () => {
    setSaveDraft(!saveDraft);
  };

  const saveDraftForTask = (task: any, formValues: Partial<AllFormFields>): Promise<any> => {
    const taskId = task.id;
    return props.saveCounselingDraft(taskId, {
      patient_id: props.therapy.patient_id,
      therapy_id: task.therapy_id,
      draft: {
        protocolData: {
          [taskId]: {
            supportiveMeds: counselingSnapshot.protocolData[taskId].supportiveMeds,
          },
        },
        formValues: {
          counselingCompletedDate: formValues.counselingCompletedDate || null,
          dcNext: formValues.dcNext![taskId] || null,
          dcPrev: formValues.dcPrev![taskId] || null,
          followUpDate: formValues.followUpDate || null,
          method: formValues.method ? formValues.method : null,

          ongoingCounselingNeeded: formValues.ongoingCounselingNeeded![taskId] || null,
          nextAssessmentDate:
            convertToArborDate(formValues.nextAssessmentDate?.[taskId]).getUtcDatetime() || null,
          ongoingCheckinNeeded: formValues.ongoingCheckinNeeded?.[taskId] || null,
          nextCheckinDate:
            convertToArborDate(formValues.nextCheckinDate?.[taskId]).getUtcDatetime() || null,
          serviceGroup: formValues.serviceGroup || null,
          sessionDuration: formValues.sessionDuration || null,
          sideEffectManagement: formValues.sideEffectManagement,
          adherence: formValues.adherence,
          administrationStatus: formValues.administrationStatus,
        },
        documentation: {
          objective: counselingSnapshot.documentation.objective,
          subjective: counselingSnapshot.documentation.subjective,
          dotPhrase:
            Object.keys(counselingSnapshot?.protocolData).length > 0
              ? counselingSnapshot.protocolData[taskId].dotPhrase
              : null,
        },
        objectiveSelectedLabValues: counselingSnapshot.objectiveSelectedLabValues?.map(dc => ({
          taskId: dc.taskId,
          dataTypeId: dc.dataTypeId,
        })),
      },
    });
  };

  const saveCounselingTaskDraft = (formValues: Partial<AllFormFields>) => {
    triggerSaveDraft();
    return props.tasks.forEach(task => {
      saveDraftForTask(task, formValues).then((result: any) => {
        // Update task in redux state with has_draft property
        if (!task.has_draft) {
          const taskId = task.id;
          props.updateTaskProperties?.(CSL, taskId, {
            has_draft: result.payload?.data?.task?.draft ? 1 : 0,
          });
        }
      });
    });
  };

  const saveDocumentationAndCounselingTask = (formValues: Partial<AllFormFields>) => {
    setFormValues(formValues);
    // Trigger save documentation in documentation component
    triggerDocumentationSave();
  };

  const saveCounselingTask = async (formValues: Partial<AllFormFields>) => {
    try {
      if (!formValues.method) {
        props.touch(nameOfFormFields('method'));
        return;
      }

      setSubmitting(true);

      const event = new Event('submit', { bubbles: true, cancelable: true });
      formRef.current?.dispatchEvent(event);

      const bundleTaskPayload = buildBundleTaskPayload(
        formValues,
        counselingSnapshot,
        props.editing,
      );

      // Need to update the tasks in the snapshot based on the tasks from the bundle. Otherwise the
      // tasks in the snapshot will be the old tasks (status_id and stuff won't be accurate)
      const tasksWithoutSnapshot = bundleTaskPayload.tasks.map(task => {
        const { task_snapshot, ...taskWithoutSnapshot } = task;
        return taskWithoutSnapshot;
      });

      bundleTaskPayload.tasks.forEach(task => {
        if (task.task_snapshot) {
          task.task_snapshot.tasks = tasksWithoutSnapshot as unknown as ICounselingTask[];
        }
      });

      if (props.editing) {
        const request = TaskCounselingClient.updateTasks(
          bundleTaskPayload.tasks,
          newAddendumContent,
        );
        await request;
        await onTasksChange(props.tasks, true);
        setNewAddendumContent(undefined);
        setAddendumClearTrigger(new Date());
        props.notifySuccess('Counseling Edited Successfully');
      } else {
        if (props.requestReview) {
          const draftSavePromises = props.tasks.map(task => saveDraftForTask(task, formValues));
          await Promise.all(draftSavePromises);
        }
        const response = await props.editTasks(
          counselingSnapshot.patientReview.patient.id,
          bundleTaskPayload.tasks,
          bundleTaskPayload.orders,
          bundleTaskPayload.notes,
          bundleTaskPayload.documents,
        );

        // Since completing CSL tasks update has_draft property
        const updatedTasks: ICounselingTask[] = response?.payload?.data?.updated_tasks ?? [];
        updatedTasks
          .filter(updatedTask => updatedTask.has_draft !== props.requestReview)
          .forEach(updatedTask => {
            props.updateTaskProperties?.(CSL, updatedTask.id, {
              has_draft: props.requestReview,
            });
          });

        handleCancel();
        props.afterSubmit?.();
      }

      handleClearSelectedTasks();

      setSubmitting(false);
    } catch (error) {
      logger.error(error);
      props.notifyError('Counseling Edit Failed');
    } finally {
      setSubmitting(false);
    }
  };
  // #endregion save snapshot and draft functions

  // #region tabs changing and validation events
  const validateTab = (tabIndex: number) => {
    if (props.viewOnly) {
      return;
    }

    const fieldsToCheck = fieldsToValidatePerTab[tabIndex];
    // if we have visited the tab...
    if (visitedTabs.has(tabIndex)) {
      // if there are any fields to check, check them
      if (fieldsToCheck && fieldsToCheck.length) {
        const fieldsWithErrors = Object.keys(props?.formErrors);
        const tabHasErrors = fieldsToCheck.some(field => {
          const fieldHasNoErrors = Array.isArray(props.formErrors[field])
            ? (props.formErrors[field] as unknown as (string | undefined)[]).every(
                x => x === undefined,
              )
            : props.formErrors[field] === undefined;
          if (fieldHasNoErrors) {
            return false;
          }
          return fieldsWithErrors.includes(field);
        });
        if (tabHasErrors) {
          setTabInvalid(tabIndex);
        } else {
          setTabValid(tabIndex);
        }
      } else {
        setTabValid(tabIndex);
      }
    } else {
      setTabInvalid(tabIndex);
    }
  };

  const setTabValid = (tabIndex: number) => {
    setTabValidStatuses(prevState => {
      return { ...prevState, [tabIndex]: true };
    });
  };

  const setTabInvalid = (tabIndex: number) => {
    setTabValidStatuses(prevState => {
      return { ...prevState, [tabIndex]: false };
    });
  };

  React.useEffect(() => {
    validateTab(currentTab);
  }, [props.formErrors]);

  React.useEffect(() => {
    visitedTabs.forEach(tab => {
      validateTab(tab);
    });
  }, [visitedTabs]);

  const handleTabChange = (
    selected?: number,
    _previous?: number,
    visitedTabs?: Set<number>,
  ): void => {
    if (visitedTabs) {
      setVisitedTabs(visitedTabs);
    }
    if (selected !== undefined) {
      setCurrentTab(selected);
      validateTab(selected);
    }
  };
  // #endregion tabs changing and validation events

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel(props.tasks.map(x => x.id));
    }
  };

  const handleSupportiveMedChecked = (taskId: number, medName: string, checked: boolean): void => {
    setCounselingSnapshot(prev => {
      const updated = {
        ...prev,
      };

      const medToUpdate = updated.protocolData[taskId].supportiveMeds?.find(
        x => x.name === medName,
      );
      if (medToUpdate) {
        medToUpdate.checked = checked;
      }
      if (updated.documentation.objective) {
        updated.documentation.objective = getPatientObjectiveText(
          props.tasks,
          state,
          counselingSnapshot.protocolData,
        );
      }

      return updated;
    });
  };

  const handleDocumentationChange = (
    section: string,
    content?: string,
    secondaryContent?: string,
    drugIndex?: number,
    subSectionIndex?: number,
    taskId?: number,
  ): void => {
    setCounselingSnapshot(prevSnapshot => {
      const result = { ...prevSnapshot };

      if (subSectionIndex != null && taskId && prevSnapshot.protocolData[taskId] && content) {
        // something like 'goals of therapy' or 'efficacy'
        const sectionName = Object.entries(prevSnapshot?.protocolData[taskId]?.dotPhrase)[
          subSectionIndex
        ][0];
        result.protocolData[taskId].dotPhrase[sectionName] = content;
      } else {
        if (content != null) {
          result.documentation[section.toLowerCase()].content = content;
        }
        if (secondaryContent != null) {
          result.documentation[section.toLowerCase()].secondaryContent = secondaryContent;
        }
      }
      return result;
    });
  };

  const handleSaveSelectedLabs = (selectedlabs: ISelectableLab[]) => {
    setCounselingSnapshot({
      ...counselingSnapshot,
      objectiveSelectedLabValues: selectedlabs,
    });
  };

  const handleUnselectLab = (labId: number) => {
    setCounselingSnapshot({
      ...counselingSnapshot,
      objectiveSelectedLabValues:
        counselingSnapshot.objectiveSelectedLabValues?.filter(
          selectedValue => selectedValue.id !== labId,
        ) || [],
    });
  };

  const disableSubmitCondition =
    !patientReviewHasReviewedDur(statePatientReview) ||
    loadingTaskData ||
    loadingProtocolData ||
    !canUserSaveCSL();
  if (loadingTaskData) {
    return <CircularProgress />;
  }

  const hasContent = (htmlString: string) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    tempElement.querySelectorAll('br').forEach(br => br.remove());
    const content = tempElement?.textContent ? tempElement.textContent.trim() : '';
    // Return true if there is meaningful content
    return content.length > 0;
  };

  return (
    <form data-qa-id="form-csl-complete" autoComplete="off">
      <Grid container className={classes.counselingContainer}>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('followUpDate')}
            label="Follow up date *"
            validate={
              props.currentCslType === CounselingTaskType.Ongoing
                ? [required, validateCslFollowupDate]
                : [required]
            }
            component={renderDatePicker}
            disabled={isSnapshot}
            qaId="add_counseling_followUpDate"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('serviceGroup')}
            label="Service Group *"
            validate={[required]}
            component={ReactSelectForReduxSingle}
            fields={serviceGroups.map((int: IServiceGroup) => ({
              label: int.display_name,
              value: int.id,
              isDisabled: !int.active,
            }))}
            disabled={isSnapshot}
            qaId="add_counseling_serviceGroup"
          />
        </Grid>
        <Grid item xs={12}>
          <EditAdministration
            therapy={therapy}
            therapyIds={therapyIds}
            medication={medication}
            tagTypeId={tagTypeId}
            tagResourceId={therapy.id}
            adminStatus={adminStatus}
            formRef={formRef}
            simplified
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid item xs={6}>
        <Field
          name={nameOfFormFields('method')}
          label="Method *"
          className={classes.radioGroup}
          radioMap={COUNSELING_METHODS}
          component={renderReactRadioGroup}
          validate={[required]}
          disabled={isSnapshot}
          width="100%"
          qaId="add_counseling_method"
        />
      </Grid>
      <VerticalTabs
        tabs={[
          {
            label: 'Patient Review',
            content: (
              <ChartReview
                tasks={counselingSnapshot.tasks}
                patientReviewData={counselingSnapshot.patientReview}
                readOnly={isSnapshot}
                viewOnly={props.viewOnly}
                hideDurMedicationChildren={isSnapshot}
                taskQty={REQUIRED_PATIENT_REVIEW_DATA_COLLECTS}
                currentSelectedTasks={props.tasks}
              />
            ),
            invalidMessage: tabInvalidMessage,
            valid: tabValidStatuses[0],
          },
          {
            label: 'Education and Counseling',
            content: (
              <EducationAndCounseling
                educationAndCounselingData={{
                  tasks: counselingSnapshot.tasks,
                  protocolData: counselingSnapshot.protocolData,
                  selectedServiceGroupId: props.formValues.serviceGroup?.value,
                }}
                change={props.change}
                formValues={props.formValues}
                sideEffects={counselingSnapshot.sideEffects}
                readOnly={isSnapshot}
                onSupportiveMedChecked={handleSupportiveMedChecked}
              />
            ),
            invalidMessage: tabInvalidMessage,
            valid: tabValidStatuses[1],
          },
          {
            label: 'Follow Up',
            content: (
              <FollowUp
                tasks={counselingSnapshot.tasks}
                therapy={props.therapy}
                taskCounts={props.taskCounts}
                therapyIndex={props.therapyIndex}
                nextAssessmentMaxDates={nextAssessmentMaxDates}
                hideNextAssessmentDates={props.formValues.ongoingCounselingNeeded}
                hideOngoingCounselingNeededQuestions={hideOngoingCounselingNeededQuestions}
                hideNextCheckinDates={props.formValues.ongoingCheckinNeeded}
                shortenTasks={counselingSnapshot.followUp.shortenTasks}
                tasksTherapies={counselingSnapshot.followUp.tasksTherapies}
                readOnly={isSnapshot}
                viewOnly={props.viewOnly}
              />
            ),
            invalidMessage: tabInvalidMessage,
            valid: tabValidStatuses[2],
          },
          {
            label: 'Documentation',
            content: (
              <Documentation
                tasks={counselingSnapshot.tasks}
                protocolData={counselingSnapshot.protocolData}
                soapOrder={counselingSnapshot.soapOrder}
                readOnly={isSnapshot}
                viewOnly={props.viewOnly}
                subjective={counselingSnapshot.documentation?.subjective}
                objective={counselingSnapshot.documentation?.objective}
                onContentChange={handleDocumentationChange}
                forceSaveDocumentationTrigger={forceSaveDocumentationTrigger}
                afterForceSaveFn={() => setForceSaveFinished(!forceSaveFinished)}
                objectiveDataCollects={counselingSnapshot.objectiveDataCollects || []}
                objectiveSelectedLabs={counselingSnapshot.objectiveSelectedLabValues || []}
                handleSaveSelectedLabs={handleSaveSelectedLabs}
                handleDeleteSelectedLab={handleUnselectLab}
                defaultToEditing={counselingSnapshot.tasks.some(
                  task => task.counseling_type === CounselingTaskType.CheckIn,
                )}
                addendums={counselingAddendums}
                addendumField={{
                  show: allTasksCompleted,
                  clearTrigger: addendumClearTrigger,
                  onChange: (newValue: string) => {
                    if (hasContent(newValue)) {
                      setNewAddendumContent(newValue);
                    }
                  },
                }}
                patientReviewData={counselingSnapshot.patientReview}
                taskQty={REQUIRED_PATIENT_REVIEW_DATA_COLLECTS}
                currentSelectedTasks={props.tasks}
              />
            ),
            invalidMessage: tabInvalidMessage,
            valid: tabValidStatuses[3],
          },
        ]}
        onChange={handleTabChange}
        showNavigationButtons
        previousButtonText="Previous"
        nextButtonText="Next"
      />
      {!patientReviewHasReviewedDur(statePatientReview) ? (
        <Typography className={props.classes.durErrorMessage}>
          A reviewed Drug Utilization Review is required
        </Typography>
      ) : null}
      {patientReviewHasReviewedDur(statePatientReview) && !subformIsCompleted && (
        <Typography className={props.classes.durErrorMessage}>
          Review the Education and Counseling tab and check the answers
        </Typography>
      )}

      <ConfirmationPanel
        cancelButtonName="complete_csl_cancel_button"
        submitButtonName="complete_csl_submit_button"
        submitButtonText="Save"
        handleCancel={handleCancel}
        handleSubmit={props.handleSubmit(saveDocumentationAndCounselingTask)}
        isLoading={submitting}
        disableSubmit={
          !subformIsCompleted ||
          !patientReviewHasReviewedDur(statePatientReview) ||
          !canUserSaveCSL()
        }
        disableSubmitAndReview={disableSubmitCondition}
        otherButtons={
          !isCounselingCompleted
            ? [
                {
                  button: (
                    <Button
                      name="save_as_draft"
                      type="button"
                      variant="contained"
                      color="primary"
                      data-qa-id="save_as_draft_button"
                      className={classes.submissionButton}
                      onClick={() => {
                        hasMounted.current = false;
                        triggerSaveDraft();
                        saveDocumentationAndCounselingTask(props.formValues);
                      }}
                    >
                      Save as draft
                    </Button>
                  ),
                },
              ]
            : []
        }
      />
    </form>
  );

  // #region rendering

  // #endregion rendering
};

// #region redux form setup
const mapStateToProps = (state: IState, props: IProps): IStateProps => {
  const dcNexts: Record<TaskId, string | null> = {};
  const dcPrevs: Record<TaskId, string | null> = {};
  const { serviceGroups } = state.lookups;
  const taskServiceGroup = serviceGroups.find(
    serviceGroup => props.tasks[0].service_group_id === serviceGroup.id,
  );

  props.tasks.forEach(task => {
    const dcTasks = Object.values(state?.tasks?.data)
      .filter(x => x.taskType === DC && x.therapy_id === task.therapy_id)
      .map(x => x as IDataCollectTask);

    const prevTasks = dcTasks.filter(
      x =>
        x.status_id === DataCollectStatus.Reviewed &&
        x.completed_dt &&
        moment(x.completed_dt).isBefore(moment()),
    );
    if (prevTasks.length) {
      dcPrevs[task.id] = prevTasks.sort((a, b) =>
        moment(a.completed_dt).diff(moment(b.completed_dt)),
      )[0].completed_dt as string;
    } else {
      dcPrevs[task.id] = null;
    }

    const nextTasks = dcTasks.filter(
      x =>
        x.status_id === DataCollectStatus.Required &&
        x.followup_dt &&
        moment.utc(x.followup_dt).isSameOrAfter(moment.utc().startOf('d')),
    );
    if (nextTasks.length) {
      dcNexts[task.id] = nextTasks.sort((a, b) =>
        moment(a.followup_dt).diff(moment(b.followup_dt)),
      )[0].followup_dt as string;
    } else {
      dcNexts[task.id] = null;
    }
  });

  const selector = formValueSelector(props.form);
  const method = selector(state, nameOfFormFields('method'));
  const dcNext = selector(state, nameOfFormFields('dcNext'));
  const dcPrev = selector(state, nameOfFormFields('dcPrev'));
  const followUpDate = selector(state, nameOfFormFields('followUpDate'));
  const sessionDuration = selector(state, nameOfFormFields('sessionDuration'));
  const nextAssessmentDate = selector(state, nameOfFormFields('nextAssessmentDate'));
  const counselingCompletedDate = selector(state, nameOfFormFields('counselingCompletedDate'));
  const ongoingCounselingNeeded = selector(state, nameOfFormFields('ongoingCounselingNeeded'));
  const ongoingCheckinNeeded = selector(state, nameOfFormFields('ongoingCheckinNeeded'));
  const nextCheckinDate = selector(state, nameOfFormFields('nextCheckinDate'));
  const serviceGroup = selector(state, nameOfFormFields('serviceGroup'));
  const sideEffectManagement = selector(state, nameOfFormFields('sideEffectManagement'));
  const adherence = selector(state, nameOfFormFields('adherence'));
  const administrationStatusFormName = handleFormName();
  const therapyAdministrationFormValues = getFormValues(administrationStatusFormName)(state);

  const initialValues: Partial<AllFormFields> = {
    // Set follow up Form initial values
    method: props.tasks[0].method,
    serviceGroup: taskServiceGroup
      ? {
          value: taskServiceGroup.id,
          label: taskServiceGroup.display_name,
        }
      : null,
    sessionDuration: props.tasks[0].duration_minutes,
    ongoingCounselingNeeded: props.tasks.reduce<Record<TaskId, number>>((acc, curr) => {
      return {
        ...acc,
        [curr.id]: 1,
      };
    }, {}),
    ongoingCheckinNeeded: props.tasks.reduce<Record<TaskId, number>>((acc, curr) => {
      return {
        ...acc,
        [curr.id]: 0,
      };
    }, {}),
    counselingCompletedDate: moment(),
    dcPrev: dcPrevs,
    dcNext: dcNexts,
    followUpDate: convertToArborDate(props.tasks[0].followup_dt, true).getUtcDate() || undefined,
  };

  const currentCslType = props.tasks[0].counseling_type;
  return {
    taskCounts: state.taskCounts,
    therapy: state.therapies.data[props.tasks[0].therapy_id],
    initialValues,
    serviceGroups,
    formErrors: getFormSyncErrors(props.form)(state),
    currentCslType,

    formValues: {
      method,
      followUpDate,
      sessionDuration,
      nextAssessmentDate,
      counselingCompletedDate,
      ongoingCounselingNeeded,
      ongoingCheckinNeeded,
      nextCheckinDate,
      serviceGroup: serviceGroup,
      dcNext,
      dcPrev,
      sideEffectManagement,
      adherence,
      administrationStatus: therapyAdministrationFormValues as IAdministrationStatus,
    },
  };
};
// #endregion redux form setup

export default compose<Props, any>(
  withStyles(styles),
  connect(mapStateToProps, {
    editTasks,
    editCounseling,
    saveCounselingDraft,
    updateTaskProperties,
    notifyError,
    notifySuccess,
    editTherapy,
  }),
  reduxForm({}),
)(Complete);
