import React from 'react';
import { Grid, CircularProgress, Button } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import withStyles from '@mui/styles/withStyles';
import CustomerDropdown from '../components/customer-dropdown';
import { SectionTitleStyled } from '../components/section-title';
import { IHocProps } from './interface';
import HolidayTable from './table';
import AddHolidayModal from './add-holidays-modal';
import { HolidayManagerStyles } from './holidays.styles';

const HolidayManager = ({
  loading,
  selectedCustomerId,
  memorizedHolidays,
  classes,
  setSelectedCustomerId,
  handleModalAddHoliday,
  showAddHolidayModal,
  submitRequestHoliday,
  handleAddHolidayCheckboxType,
  holidayTypes,
}: IHocProps): JSX.Element => {
  const hasAddHolidayPermission = useTypedSelector(state =>
    state.auth.currentUser.roles
      .filter(role => role.resource_name === 'shipping_holidays')
      .find(item => item.resource_action === 'create'),
  );
  return (
    <>
      <SectionTitleStyled title="Holidays Manager" />
      <AddHolidayModal
        open={showAddHolidayModal}
        close={handleModalAddHoliday}
        submitRequestHoliday={submitRequestHoliday}
        handleAddHolidayCheckboxType={handleAddHolidayCheckboxType}
        holidayTypes={holidayTypes}
      />
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CustomerDropdown
                isLabelOn
                isSelectedOption
                selectedCustomerId={selectedCustomerId}
                setSelectedCustomerId={customerId => setSelectedCustomerId(customerId)}
              />
            </Grid>
            {selectedCustomerId && hasAddHolidayPermission ? (
              <Grid item xs={3} marginLeft={5}>
                <Button
                  name="addModalHoliday"
                  type="button"
                  onClick={handleModalAddHoliday}
                  className={classes.saveButton}
                  id="addModalHolidayButton"
                  variant="contained"
                  color="primary"
                >
                  + Add Holiday
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {loading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : memorizedHolidays.length === 0 ? (
          selectedCustomerId === 0 || 'No holidays for this customer'
        ) : (
          <HolidayTable data={memorizedHolidays} />
        )}
      </Grid>
    </>
  );
};

export default withStyles(HolidayManagerStyles)(HolidayManager);
