/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { addPaymentMethodModalActions } from 'actions/action-add-payment-method-modal';
import { arrayPush, reset, untouch } from 'redux-form';
import { IState } from 'interfaces/redux/IState';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { notifyError } from 'actions/action-notifications';
import { useDeepDiffSelector } from 'utils/deep-diff-props';
import { ViewModeEnum } from 'containers/add-payment-method/add-payment-method-modal';
import { IAddPaymentMethodFields, IAddPaymentMethodPayload } from './types';
import { postPaymentMethod } from './payment-methods-client';
import { ADDED_PAYMENT_METHOD } from '../../constants';

export const useTherapyAssociatedOptions = (tasks?: IFillCoordinationTask[]) =>
  useDeepDiffSelector(
    state =>
      tasks
        ?.reduce(
          (ids, task) => (ids.includes(task.therapy_id) ? ids : ids.concat(task.therapy_id)),
          [] as number[],
        )
        .map(therapyId => ({
          value: therapyId,
          label: state.therapies.data[therapyId]?.drug_name,
        }))
        .filter(t => Boolean(t.label)) ?? [],
  );

interface UseAddPaymentMethodArgs {
  formName?: string;
}

export const selectPatientPrimaryPaymentMethod = (state: IState) =>
  state.patient.payment_methods?.find(paymentMethod => paymentMethod.rank === 1);

const ADD_PAYMENT_METHOD_FORM_NAME = 'add-payment-method-modal';
export function useAddPaymentMethod(args?: UseAddPaymentMethodArgs) {
  const formName = args?.formName ?? ADD_PAYMENT_METHOD_FORM_NAME;
  const {
    formId,
    field,
    visible: isModalVisible,
    tasks,
    viewMode,
  } = useTypedSelector(state => state.addPaymentMethodModal);
  const selectedPatientId = useTypedSelector(state => state.selectedPatientId);
  const patientHasPrimaryPaymentMethod = useTypedSelector(state =>
    Boolean(selectPatientPrimaryPaymentMethod(state)),
  );
  const therapyFcIds: { [k: number]: number } | undefined = tasks?.reduce(
    (res, { id, therapy_id }) => ({ ...res, [therapy_id]: id }),
    {},
  );
  const dispatch = useDispatch();
  const cancel = (): void => {
    dispatch(reset(formName));
    if (formId && field) {
      dispatch(untouch(formId, field));
    }
    dispatch(addPaymentMethodModalActions.resetModal());
  };

  const handleSubmit = async (formValues: IAddPaymentMethodFields) => {
    if (
      formValues.add_payment_method_card_number === null ||
      formValues.add_payment_method_expiration_date === null ||
      formValues.add_payment_method_type === null
    ) {
      dispatch(notifyError('Please fill out all fields.'));
      return;
    }
    const payload: IAddPaymentMethodPayload = {
      card_num: formValues.add_payment_method_card_number,
      expiration_date: formValues.add_payment_method_expiration_date,
      payment_type: formValues.add_payment_method_type,
      rank: null,
    };
    // If the card is a virtual card, make sure it has therapy_id and the rank is null
    if (formValues.add_payment_method_type === 4) {
      const therapyId = formValues.add_payment_method_therapy_associated_id;
      const fcTaskId = therapyFcIds && therapyId ? therapyFcIds[therapyId] : null;
      if (!therapyId || !fcTaskId) {
        dispatch(notifyError('Virtual cards must have a therapy assigned.'));
        return;
      }
      payload.therapy_id = therapyId;
      payload.rank = null;
    } else if (!patientHasPrimaryPaymentMethod) {
      // If the patient has no primary payment method, set the new payment method as primary
      payload.rank = 1;
    }
    try {
      // Save the new payment method
      const {
        data: { added_payment_method },
      } = await postPaymentMethod(selectedPatientId, payload);
      // Put the added payment method in the store (if its for a patient, put it in patient preferences)
      dispatch({ type: ADDED_PAYMENT_METHOD, payload: { added_payment_method } });
      if (formId && field) {
        // Add the payment method to the order by pushing the new id onto the formValues.payment_method_ids
        dispatch(arrayPush(formId, field, added_payment_method.id));
        dispatch(reset(formName));
      }
      dispatch(addPaymentMethodModalActions.resetModal());
    } catch (err: unknown) {
      console.error(err);
    }
  };

  return {
    paymentMethodTypeOptions: useTypedSelector(state => state.lookups.paymentMethodTypes),
    therapyAssociatedOptions: useTherapyAssociatedOptions(tasks),
    onSubmit: handleSubmit,
    onCancel: cancel,
    isModalVisible,
    viewMode,
  };
}
