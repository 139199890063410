import React from 'react';
import { Button } from '@mui/material';
import Paragraph from '../components/paragraph';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';

const buttonStyle = {
  width: '85%',
  height: '60px',
  fontSize: '16px',
  backgroundColor: '#547096',
  color: 'white',
};

const TransitionPage = props => (
  <BlueContainer>
    <Paragraph type="title">
      We have to collect some information from you before we start your refill. <br />
      <br />
      There will be a total of {props.questionnaire_count} questionnaire
      {props.questionnaire_count > 1 ? 's' : ''}.
    </Paragraph>
    <Button style={buttonStyle} onClick={props.continue}>
      Start
    </Button>
    <PoweredBy />
  </BlueContainer>
);

export default TransitionPage;
