import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import murmurhash from 'murmurhash-js';
import { AddressValidationClient } from '../../../../clients/address-validation-client';
import { AddressValidationData } from './address-validation-data';

type Props = {
  addressObject?: any;
  details: any;
  callback: Function;
};

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 20,
    minWidth: 600,
  },
  dialog: {
    top: '5%',
  },
}));

const redInputStyling = {
  '& .MuiInputLabel-root': { color: 'red' },
  '& .MuiInputBase-input': { color: 'red' },
  '& .MuiInput-underline:before': { borderBottomColor: 'red' },
};

const AddressValidationModal: React.FC<Props> = ({
  addressObject,
  callback,
  details,
}): JSX.Element | null => {
  const [submittedAddress, setSubmittedAddress] = useState(addressObject);
  const [showValidationData, setShowValidationData] = useState(false);
  const [validationDetails, setValidationDetails] = useState(details);
  const [submitting, setSubmitting] = useState(false);
  const addressValue = addressObject.value;
  const google_address_components: any = {};
  validationDetails.data.result.address.addressComponents.forEach((component: any) => {
    google_address_components[component.componentType] = component.componentName.text;
  });
  const [line1, setLine1] = useState(
    `${
      google_address_components.street_number ? `${google_address_components.street_number} ` : ''
    }${google_address_components.route}`,
  );
  const [line2, setLine2] = useState(google_address_components.subpremise || null);
  const [city, setCity] = useState(google_address_components.locality);
  const [state, setState] = useState(google_address_components.administrative_area_level_1);
  const [zip, setZip] = useState(
    `${google_address_components.postal_code}${
      google_address_components.postal_code_suffix
        ? `-${google_address_components.postal_code_suffix}`
        : ''
    }`,
  );
  const classes = useStyles();
  const usps_data = validationDetails.data.result.uspsData;
  const only_zip_or_subpremise_needs_changes =
    validationDetails.data.result.address.addressComponents.every((component: any) => {
      if (component.componentType === 'subpremise') {
        return true;
      }
      if (
        component.componentType !== 'postal_code_suffix' &&
        component.componentType !== 'postal_code'
      ) {
        return (
          component.confirmationLevel === 'CONFIRMED' && !component.inferred && !component.replaced
        );
      }
      return component.confirmationLevel === 'CONFIRMED';
    });

  const changes_from_original_value =
    line1?.toLowerCase() !== addressValue?.line1?.toLowerCase() ||
    (line2 && !addressValue?.line2) ||
    (!line2 && addressValue?.line2) ||
    (line2 && addressValue?.line2 && line2?.toLowerCase() !== addressValue?.line2?.toLowerCase()) ||
    city?.toLowerCase() !== addressValue?.city?.toLowerCase() ||
    state?.toLowerCase() !== addressValue?.state?.toLowerCase() ||
    zip !== addressValue.zip;

  const click_handler = (e: any) => {
    if (e.altKey && e.shiftKey) {
      setShowValidationData(!showValidationData);
    }
  };

  const updated_fields = [];
  const addr = addressValue;

  if (addr.city?.toLowerCase() !== google_address_components.locality.toLowerCase()) {
    updated_fields.push('city');
  }
  if (
    addr.state?.toLowerCase() !==
    google_address_components.administrative_area_level_1?.toLowerCase()
  ) {
    updated_fields.push('state');
  }
  if (
    addr.zip !==
    `${google_address_components.postal_code}${
      google_address_components.postal_code_suffix
        ? `-${google_address_components.postal_code_suffix}`
        : ''
    }`
  ) {
    updated_fields.push('zip code');
  }
  const retry_button_disabled = !line1 || !city || !state || !zip;

  const validateAsIs = () => {
    const addr_with_hash = JSON.parse(JSON.stringify(addressObject)) as any;
    const validated_addr = `${line1}${
      line2 ? `, ${line2}` : ''
    }, ${city}, ${state}, ${zip}`.toLowerCase();
    const validated_hash = murmurhash.murmur3(validated_addr);
    if (addr_with_hash.label) {
      const split_token = addressValue.use;
      const partA = addr_with_hash.label.split(` - ${split_token} - `)[0];
      addr_with_hash.label = `${partA} - ${split_token} - ${line1}${
        line2 ? `, ${line2}` : ''
      }, ${city}, ${state}, ${zip}`;
    }
    addr_with_hash.value.hash = -1 * validated_hash;
    addr_with_hash.value.line1 = line1;
    addr_with_hash.value.line2 = line2;
    if (!line2) {
      delete addr_with_hash.value.line2;
    }
    addr_with_hash.value.city = city;
    addr_with_hash.value.state = state;
    addr_with_hash.value.zip = zip;
    const result = { updated_address: addr_with_hash, validated: true };
    callback(result);
  };

  const validateOriginalValue = () => {
    const addr = addressObject.value;
    const validated_addr = `${addr.line1}${addr.line2 ? `, ${addr.line2}` : ''}, ${addr.city}, ${
      addr.state
    }, ${addr.zip}`.toLowerCase();
    const validated_hash = murmurhash.murmur3(validated_addr);
    const addr_with_hash = { ...addressObject } as any;
    addr_with_hash.value.hash = -1 * validated_hash;
    addr_with_hash.value.line1 = line1;
    addr_with_hash.value.line2 = line2;
    if (!line2) {
      delete addr_with_hash.value.line2;
    }
    addr_with_hash.value.city = city;
    addr_with_hash.value.state = state;
    addr_with_hash.value.zip = zip;
    if (addressObject.contact_id) {
      addr_with_hash.contact_id = addressObject.contact_id;
    }
    const result = { updated_address: addr_with_hash, validated: true };
    callback(result);
  };

  const retryValidation = async () => {
    setSubmitting(true);
    const updatedAddress = JSON.parse(JSON.stringify(addressObject)) as any;
    if (addressObject.label) {
      const split_token = addressValue.use;
      const partA = addressObject.label.split(` - ${split_token} - `)[0];
      updatedAddress.label = `${partA} - ${split_token} - ${line1}${
        line2 ? `, ${line2}` : ''
      }, ${city}, ${state}, ${zip}`;
    }
    updatedAddress.value.line1 = line1;
    updatedAddress.value.line2 = line2;
    if (!line2) {
      delete updatedAddress.value.line2;
    }
    updatedAddress.value.city = city;
    updatedAddress.value.state = state;
    updatedAddress.value.zip = zip;
    const validation_result = await AddressValidationClient.validateAddress(updatedAddress);
    setSubmitting(false);
    if (validation_result.validated) {
      updatedAddress.value.hash = validation_result.updated_address.value.hash;
      const result = { updated_address: updatedAddress, validated: true };
      callback(result);
    } else {
      setSubmittedAddress(updatedAddress);
      setValidationDetails(validation_result.validation_results);
      const address_components: any = {};
      validation_result.validation_results.data.result.address.addressComponents.forEach(
        (component: any) => {
          address_components[component.componentType] = component.componentName.text;
        },
      );
      setLine1(
        `${address_components.street_number ? `${address_components.street_number} ` : ''}${
          address_components.route
        }`,
      );
      setLine2(address_components.subpremise || null);
      setCity(address_components.locality);
      setState(address_components.administrative_area_level_1);

      setZip(
        `${address_components.postal_code}${
          address_components.postal_code_suffix ? `-${address_components.postal_code_suffix}` : ''
        }`,
      );
    }
  };

  const renderCancelButton = () => {
    return (
      <DialogActions>
        <Button
          variant="contained"
          style={{ backgroundColor: '#547096' }}
          onClick={() => callback({ validated: false })}
          data-qa-id="cancel-address-validation-button"
        >
          Cancel
        </Button>
      </DialogActions>
    );
  };

  const updateField = (event: any, name: string) => {
    const val = event.nativeEvent.srcElement.value;
    if (name === 'line1') {
      setLine1(val);
    } else if (name === 'line2') {
      setLine2(val || null);
    } else if (name === 'city') {
      setCity(val);
    } else if (name === 'state') {
      setState(val);
    } else if (name === 'zip') {
      setZip(val);
    }
  };
  const renderUseOriginalValueSection = () => (
    <>
      <hr />
      <Grid style={{ height: '175px', marginTop: '15px' }}>
        <Grid>
          <Typography style={{ fontSize: '12px', paddingBottom: '10px' }}>
            <span style={{ color: 'red' }}>Caution: </span>Manually validated addresses are at an
            increased risk for delivery errors. User is acknowledging that the address is correct
            despite not matching any address found in the database.
          </Typography>
          <Typography variant="h6" style={{ paddingBottom: '10px' }}>
            If the original information is the most correct, you can mark it as manually validated.{' '}
          </Typography>
          <Grid
            style={{
              fontSize: '20px',
              fontWeight: '325',
              lineHeight: '22px',
              textTransform: 'capitalize',
            }}
          >
            <Grid>{addressValue.line1}</Grid>
            <Grid>{addressValue.line2}</Grid>
            <Grid>{`${addressValue.city}, ${addressValue.state}, ${addressValue.zip}`}</Grid>
          </Grid>
          <Button
            style={{ marginTop: '20px', backgroundColor: '#547096' }}
            onClick={validateOriginalValue}
            variant="contained"
            data-qa-id="validate-original-address-button"
          >
            Validate Manually
          </Button>
        </Grid>
      </Grid>
    </>
  );
  const changes_message = `Arbor suggests the following changes to ${updated_fields.join(', ')}. `;
  const street_number_problematic =
    (
      validationDetails.data.result.address.addressComponents.find(
        (component: any) => component.componentType === 'street_number',
      ) || {}
    ).confirmationLevel !== 'CONFIRMED' &&
    (
      validationDetails.data.result.address.addressComponents.find(
        (component: any) => component.componentType === 'street_number',
      ) || {}
    ).componentName?.text;

  const street_number_missing = !google_address_components.street_number;
  const line1_is_problematic = street_number_problematic || street_number_missing;

  let dialog_message = (
    <>
      This address was not able to be validated. {updated_fields.length ? changes_message : ''}{' '}
      {street_number_problematic ? 'The street number may not be valid for this address. ' : ''}{' '}
      {street_number_missing ? 'The street number is missing. ' : ''}
      Please review the data, update as needed, and try again.
    </>
  );
  if (only_zip_or_subpremise_needs_changes && usps_data.dpvConfirmation === 'D') {
    dialog_message = (
      <>
        This address is valid but appears to be missing an apartment, suite, or other clarifying
        information.
        <br />
        Please review the information, adjust as needed, and try again.
      </>
    );
  }
  if (only_zip_or_subpremise_needs_changes && usps_data.dpvConfirmation === 'S') {
    dialog_message = (
      <>
        This address is valid but appears to have an inaccurate apartment, suite, or other
        clarifying information.
        <br />
        Please review the information, adjust as needed, and try again.
      </>
    );
  }

  const line2_is_problematic =
    usps_data.dpvConfirmation === 'S' || usps_data.dpvConfirmation === 'D';

  return (
    <Dialog open maxWidth="lg">
      {submitting ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Grid style={{ padding: '30px' }} onClick={e => click_handler(e)}>
        <DialogTitle
          id="simple-dialog-title"
          className={classes.title}
          style={{ padding: 0, marginBottom: 0, fontSize: '18px' }}
          data-qa-id="address_validation_modal"
        >
          Validate Address
        </DialogTitle>
        <hr style={{ width: '100%' }} />
        <Grid>
          <Grid style={{ height: '250px' }}>
            <Grid>
              <Typography variant="h6" style={{ paddingBottom: '30px' }}>
                {dialog_message}
              </Typography>
              <Grid>
                <TextField
                  id="line1"
                  onChange={e => updateField(e, 'line1')}
                  variant="standard"
                  label="Line 1 *"
                  value={line1}
                  sx={{
                    width: '30%',
                    marginRight: '10px',
                    ...(line1_is_problematic ? redInputStyling : {}),
                  }}
                  helperText={updated_fields.includes('line 1') ? 'ARBOR SUGGESTS' : null}
                  FormHelperTextProps={{ sx: { color: '#456189', fontWeight: 'bold' } }}
                  inputProps={{ 'data-qa-id': 'address_validation_modal_line1_input' }}
                />
                <TextField
                  id="line2"
                  onChange={e => updateField(e, 'line2')}
                  variant="standard"
                  label="Line 2"
                  value={line2}
                  sx={{
                    width: '30%',
                    marginRight: '10px',
                    ...(line2_is_problematic ? redInputStyling : {}),
                  }}
                  inputProps={{
                    'data-lpignore': true,
                    'autocomplete': 'off',
                    'data-qa-id': 'address_validation_modal_line2_input',
                  }}
                  helperText={updated_fields.includes('line2') ? 'ARBOR SUGGESTS' : null}
                  FormHelperTextProps={{ sx: { color: '#456189', fontWeight: 'bold' } }}
                />
                <TextField
                  id="city"
                  onChange={e => updateField(e, 'city')}
                  variant="standard"
                  label="City *"
                  value={city}
                  sx={{ width: '15%', marginRight: '10px' }}
                  inputProps={{
                    'data-lpignore': true,
                    'autocomplete': 'off',
                    'data-qa-id': 'address_validation_modal_city_input',
                  }}
                  helperText={updated_fields.includes('city') ? 'ARBOR SUGGESTS' : null}
                  FormHelperTextProps={{ sx: { color: '#456189', fontWeight: 'bold' } }}
                />
                <TextField
                  id="state"
                  onChange={e => updateField(e, 'state')}
                  variant="standard"
                  label="State *"
                  value={state}
                  sx={{ width: '5%', marginRight: '10px' }}
                  inputProps={{
                    'data-lpignore': true,
                    'autocomplete': 'off',
                    'data-qa-id': 'address_validation_modal_state_input',
                  }}
                  helperText={updated_fields.includes('state') ? 'ARBOR SUGGESTS' : null}
                  FormHelperTextProps={{ sx: { color: '#456189', fontWeight: 'bold' } }}
                />
                <TextField
                  id="zip"
                  onChange={e => updateField(e, 'zip')}
                  variant="standard"
                  label="Zip *"
                  sx={{ width: '10%', marginRight: '10px' }}
                  value={zip}
                  inputProps={{
                    'data-lpignore': true,
                    'autocomplete': 'off',
                    'data-qa-id': 'address_validation_modal_zip_input',
                  }}
                  helperText={updated_fields.includes('zip code') ? 'ARBOR SUGGESTS' : null}
                  FormHelperTextProps={{ sx: { color: '#456189', fontWeight: 'bold' } }}
                />
              </Grid>
              <Grid style={{ marginTop: '10px', display: 'flex' }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#547096' }}
                  onClick={retryValidation}
                  disabled={retry_button_disabled}
                  data-qa-id="retry-auto-validation-button"
                >
                  Retry Auto Validation
                </Button>
                {changes_from_original_value ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#547096', marginLeft: '15px' }}
                    onClick={validateAsIs}
                    disabled={retry_button_disabled}
                    data-qa-id="manually-validate-address-button"
                  >
                    Validate Manually
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {renderUseOriginalValueSection()}
        </Grid>
        {renderCancelButton()}
        {showValidationData ? (
          <AddressValidationData
            validation_data={validationDetails}
            submittedAddress={submittedAddress}
          />
        ) : null}
      </Grid>
    </Dialog>
  );
};

export default AddressValidationModal;
