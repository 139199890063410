import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getClinicalDataTypesMap,
  getFieldsIdsWithConditional,
  mapValuesToClinicalDataType,
  getDataToUpdateClinicalDataItems,
} from 'services/utils/data-collect';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { RS, DC } from '../../../constants';
import { useClinicalDataDCTask } from '../../../containers/patient/clinical/use-clinical-data';
import DataCollectForm from '../task-forms/data-collect-form';

const checkMissingItems = (valueKeys, clinicalDataItems) => {
  return clinicalDataItems.reduce((acc, x) => {
    if (!acc.map(i => i.id).includes(x.clinicalDataTypeId)) {
      acc.push({ id: x.clinicalDataTypeId });
    }
    return acc;
  }, valueKeys);
};

const createClinicalDataItemsFromFormValues = (values, clinicalDataTypesMap) => {
  const selectedFields = values && values['additional-field'] ? values['additional-field'] : [];
  const selectedClinicalDataTypeIds = selectedFields
    ? selectedFields.map(f => f.split('-')[1])
    : [];
  // append keys of entered values
  const allFields = Object.keys(clinicalDataTypesMap).map(
    clinicalDataTypeId => clinicalDataTypesMap[clinicalDataTypeId],
  );
  const clinicalDataItems = selectedClinicalDataTypeIds.map(clinicalDataTypeId =>
    mapValuesToClinicalDataType(values, clinicalDataTypeId),
  );

  const valuesInItems = selectedClinicalDataTypeIds.some(x => values[`${x}-value`] !== undefined);
  const idsWithConditionalsFromFieldsWithValues = valuesInItems
    ? getDataToUpdateClinicalDataItems(values, selectedFields, allFields)
    : [];

  const buildClinicalDataItems = idsWithConditionalsFromFieldsWithValues.map(key => {
    const dataTypeId = key.id;
    const mappedClinicalDataItem = clinicalDataItems.find(dcItem => dcItem.id === dataTypeId);
    if (mappedClinicalDataItem) {
      return mappedClinicalDataItem;
    }
    return mapValuesToClinicalDataType(values, dataTypeId);
  });

  const clinicalDataItemsData =
    buildClinicalDataItems.length > 0 ? buildClinicalDataItems : clinicalDataItems;
  const valueKeys = getFieldsIdsWithConditional(selectedFields, allFields, clinicalDataItemsData);
  return checkMissingItems(valueKeys, clinicalDataItemsData).map(key => {
    const dataTypeId = key.id;
    const mappedClinicalDataItem = clinicalDataItemsData.find(dcItem => dcItem.id === dataTypeId);
    if (mappedClinicalDataItem) {
      return mappedClinicalDataItem;
    }
    return mapValuesToClinicalDataType(values, dataTypeId);
  });
};

export function HookedDataCollectForm({
  input,
  required,
  isAdd,
  fullAccess,
  meta,
  dataCollectInformation,
  clinicalDataItems,
  isRiskStrat,
  providerTaskId,
  formId,
  dcFormId,
  therapyId,
  workingAsGroup,
  workingAsGroupTasks,
}) {
  const dc = input.value.values;

  // TODO: move this inside onChange for less processing?
  const clinicalDataTypes = useSelector(state => state.lookups.clinicalDataTypes);
  const therapyFromStore = useSelector(state => state.therapies.data[therapyId]);
  const selectedTaskKeys = useSelector(state => state.tasks.selectedTaskKeys);
  const mergeInterventionMapping = useSelector(state => state.tasks.mergeInterventionMapping);
  const therapy =
    isRiskStrat && dataCollectInformation?.therapy
      ? dataCollectInformation.therapy
      : therapyFromStore;
  const patient = useSelector(state => state.patient);
  const dcClinicalData = useClinicalDataDCTask(
    patient.id,
    dataCollectInformation?.id,
    mergeInterventionMapping,
    selectedTaskKeys,
  );
  if (!isRiskStrat && (!workingAsGroup || workingAsGroup.length === 0)) {
    clinicalDataItems = dcClinicalData;
  }
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  const questionnaireDataTypes = [];
  Object.keys(clinicalDataTypesMap).forEach(dataTypeId => {
    const field = clinicalDataTypesMap[dataTypeId];
    if (field.type === 'questionnaire') {
      questionnaireDataTypes.push(dataTypeId);
    }
  });

  const handleOnChange = useCallback(
    values => {
      const clinicalDataItemValues = createClinicalDataItemsFromFormValues(
        values,
        clinicalDataTypesMap,
      );
      input.onChange({
        valid: input.value.valid,
        values: clinicalDataItemValues,
      });
      input.onBlur();
    },
    [input, dc, questionnaireDataTypes, clinicalDataTypesMap, workingAsGroup],
  );

  const handleValidityChange = useCallback(
    valid => {
      if (valid !== input.value.valid) {
        input.onChange({
          values: input.value.values,
          valid,
        });
      }
    },
    [input],
  );

  const handleFailedTherapySubmitted = useCallback(
    (fieldData, failedTherapy) => {
      const updatedDcItems = dc.map(dcItem => {
        if (dcItem.dataType === fieldData.dataTypeField.dataType) {
          return {
            ...dcItem,
            value: failedTherapy,
          };
        }
        return dcItem;
      });
      setImmediate(() => {
        input.onChange({
          ...input.value,
          values: updatedDcItems,
        });
      });
    },
    [dc, input],
  );

  if (!clinicalDataItems) {
    // TODO Show spinner while the clinicalDataItems are not yet loaded
    return null;
  }

  return (
    <DataCollectForm
      clinicalDataItems={isRiskStrat ? clinicalDataItems : dc || clinicalDataItems}
      initialClinicalDataItems={clinicalDataItems}
      workingAsGroupClinicalDataItems={
        workingAsGroup && workingAsGroup.length > 0 ? clinicalDataItems : null
      }
      isAdd={isAdd}
      isRiskStrat={isRiskStrat}
      providerTaskId={providerTaskId}
      fullAccess={fullAccess}
      onValidityChange={handleValidityChange}
      onChange={handleOnChange}
      meta={meta}
      required={required}
      dataCollectInformation={dataCollectInformation}
      formId={formId}
      dcFormId={dcFormId}
      therapy={therapy}
      patient={patient}
      onFailedTherapySubmitted={handleFailedTherapySubmitted}
      workingAsGroup={workingAsGroup}
      workingAsGroupTasks={workingAsGroupTasks}
      dcList={dc}
      clinicalDataItemsList={clinicalDataItems}
    />
  );
}

export default function ({
  providers,
  field,
  label,
  input,
  meta,
  disabled,
  labelSize,
  qaId,
  formId,
}) {
  const clinicalData = useSelector(state => state.clinicalData);
  const selectedWagTaskKeys = useSelector(state => state.tasks?.selectedWagTaskKeys || []);
  const taskId = providers && providers.task ? providers.task.id : null;
  const clinicalDataItems =
    providers && providers.task ? providers.task.patientClinicalDataItems : null;
  const newStatusId = providers && providers.formValues ? providers.formValues.status_id : null;
  const isAdd = !taskId;
  const therapyId = providers.task.therapy_id;
  const workingAsGroup =
    providers.formValues && selectedWagTaskKeys.length ? providers.formValues.work_as_group : null;
  const workingAsGroupTasks =
    providers.formValues && selectedWagTaskKeys.length
      ? providers.formValues.work_as_group_tasks
      : null;

  let workingAsGroupClinicalData =
    workingAsGroup && workingAsGroup.length > 0
      ? clinicalData.filter(data =>
          workingAsGroup.some(therapyIdVar => data.therapyId === therapyIdVar),
        )
      : clinicalDataItems;

  React.useEffect(() => {
    const workingAsGroup =
      providers.formValues && selectedWagTaskKeys.length
        ? providers.formValues.work_as_group
        : null;
    const workingAsGroupTasks =
      providers.formValues && selectedWagTaskKeys.length
        ? providers.formValues.work_as_group_tasks
        : null;

    workingAsGroupClinicalData =
      workingAsGroup && workingAsGroup.length > 0
        ? clinicalData.filter(data =>
            workingAsGroup.some(therapyIdVar => data.therapyId === therapyIdVar),
          )
        : clinicalDataItems;
  }, [selectedWagTaskKeys]);

  return (
    <HookedDataCollectForm
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      isRiskStrat={providers.task.taskType === RS}
      providerTaskId={taskId}
      clinicalDataItems={workingAsGroupClinicalData}
      required={newStatusId > DataCollectStatus.InProgress}
      isAdd={isAdd}
      therapyId={therapyId}
      fullAccess={field.meta.fullAccess}
      dataCollectInformation={providers[field.provider]}
      formId={`${formId}-${field.id}`}
      dcFormId={formId}
      workingAsGroup={workingAsGroup}
      workingAsGroupTasks={workingAsGroupTasks}
    />
  );
}
