import React from 'react';
import { CheckCircle, Info as InfoIcon, AccountCircle } from '@mui/icons-material';
import { Field } from 'redux-form';
import { Tooltip } from '@mui/material';
import murmurhash from 'murmurhash-js';

const AddressValidationIcon = (props: any) => {
  const { formValues, nameOfFormFields } = props;
  if (!formValues?.address?.value) {
    return null;
  }
  return (
    <Field
      name={nameOfFormFields('address')}
      component={() => {
        const addr = formValues?.address?.value as unknown as any;
        const str_addr = `${addr.line1}${addr.line2 ? `, ${addr.line2}` : ''}, ${addr.city}, ${
          addr.state
        }, ${addr.zip}`.toLowerCase();
        const hash = murmurhash.murmur3(str_addr);

        if (Number(addr.hash) > 0 && Number(addr.hash) === hash) {
          return (
            <Tooltip
              title="Fully Validated Address"
              placement="top"
              data-qa-id="address-fully-validated-icon"
            >
              <CheckCircle style={{ color: 'green', marginLeft: '15px' }} />
            </Tooltip>
          );
        }
        if (addr.hash && Number(addr.hash) < 0 && Math.abs(Number(addr.hash)) === hash) {
          return (
            <Tooltip
              title="Manually Validated Address"
              placement="top"
              data-qa-id="address-manually-validated-icon"
            >
              <AccountCircle style={{ color: 'green', marginLeft: '15px' }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip
            title="Address Not Validated"
            placement="top"
            data-qa-id="address-not-validated-icon"
          >
            <InfoIcon style={{ color: '#d94c44', marginLeft: '15px' }} />
          </Tooltip>
        );
      }}
    />
  );
};

export default AddressValidationIcon;
