// TODO: Need to fix the lengths in this file.
/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { EditPencilMd, CircleIndicatorIcon } from 'components/icons/icons';
import DetailField from 'components/form/field/field';
import Audit from 'components/display/audit';
import ObsoleteNdcTooltip from 'containers/patient/therapy/obsolete-tooltip';
import { convertToArborDate } from 'models/time/arbor-date';
import { auditContext } from 'constants/lists';
import {
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS,
  THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG,
  THERAPY_ADMIN_STATUS_ON_HOLD,
  THERAPY_ADMIN_STATUS_ON_THERAPY,
  THERAPY_SHOULD_HAVE_START_DATE,
  THERAPY_ADMIN_STATUS_NO_GO,
} from 'constants/index';
import { styles } from './therapy-detail.styles';
import TherapyAdministrationHistory from './therapy-administration-history';

const TherapyAdministration = props => {
  const {
    classes,
    theme,
    adminStatus,
    adminStatusDisplay,
    startDate,
    discontinuedDate,
    recheckDate,
    restartDate,
    toggleEditAdministration,
    therapy,
    medication,
    index,
    noGoDate,
  } = props;

  const therapyIdArr = useMemo(() => (therapy ? [therapy.id] : []), [therapy]);
  return (
    <>
      <Grid container>
        <Grid item>
          <Typography className={classes.statusTitle} variant="h6">
            Administration
          </Typography>
        </Grid>
        <Grid item xs className={classes.actionIconContainer}>
          <Typography variant="caption">
            <Button
              name="edit_administration_button"
              onClick={toggleEditAdministration}
              className={classes.toggleEdit}
            >
              <EditPencilMd id={`editAdministrationPencil_${index}`} />
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2} className={classes.iconContainer}>
          <CircleIndicatorIcon
            size={20}
            color={theme.palette.map.therapyAdministration[adminStatus.status]}
          />
        </Grid>
        {!adminStatusDisplay.includes('Other') && (
          <Grid item xs className={classes.textContainer}>
            <Typography
              variant="caption"
              color="primary"
              className={classes.administrationTitle}
              id={`admin_status_display_not_other_${index}`}
            >
              {adminStatusDisplay}
            </Typography>
            <Grid container>
              {medication && !medication.is_active && <ObsoleteNdcTooltip />}
              <Audit
                iconType="warning"
                auditRules={[THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG]}
                context={auditContext.Warning}
                resource="therapy_id"
                resourceValue={therapy.id}
              />
            </Grid>
          </Grid>
        )}
        {!therapy.administration_status_additional_reason &&
          adminStatusDisplay.includes('Other') && (
            <Grid item xs>
              <Typography
                variant="caption"
                color="primary"
                className={classes.administrationTitle}
                id={`admin_status_display_other_${index}`}
              >
                {adminStatusDisplay}
              </Typography>
              {medication && !medication.is_active && <ObsoleteNdcTooltip />}
            </Grid>
          )}
        {!!therapy.administration_status_additional_reason &&
          adminStatusDisplay.includes('Other') && (
            <Grid item xs>
              <Typography
                variant="caption"
                color="primary"
                className={classes.administrationTitle}
                id={`admin_status_display_both_${index}`}
              >
                {`${adminStatusDisplay} - ${therapy.administration_status_additional_reason}`}
              </Typography>
            </Grid>
          )}
        <Audit
          iconType="warning"
          auditRules={[ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS]}
          context={auditContext.Warning}
          resource="therapy_id"
          resourceValue={therapy.id}
        />
        <Grid item xs justifyItems="flex-start" data-qa-id="administration_date">
          {!!startDate && (
            <DetailField
              fieldName="Start Date"
              field={`${convertToArborDate(startDate, true).getUtcDate(true) || '-'}`}
            />
          )}
          {!startDate && adminStatus.status === THERAPY_ADMIN_STATUS_ON_THERAPY && (
            <DetailField fieldName="Start Date" field="Unknown Start Date" />
          )}

          {!!recheckDate && adminStatus.status === THERAPY_ADMIN_STATUS_ON_HOLD && (
            <DetailField
              fieldName="Recheck Date"
              field={`${convertToArborDate(recheckDate, true).getUtcDate(true) || '-'}`}
            />
          )}
          {!!restartDate && adminStatus.status === THERAPY_ADMIN_STATUS_ON_THERAPY && (
            <DetailField
              fieldName="Restart Date"
              field={`${convertToArborDate(restartDate, true).getUtcDate(true) || '-'}`}
            />
          )}
          {!!noGoDate && adminStatus.status === THERAPY_ADMIN_STATUS_NO_GO && (
            <DetailField
              fieldName="No go Date"
              field={`${convertToArborDate(noGoDate, true).getUtcDate(true) || '-'}`}
            />
          )}
          <TherapyAdministrationHistory therapyIds={therapyIdArr} patientId={therapy.patient_id} />
        </Grid>

        <Audit
          iconType="warning"
          auditRules={[THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE]}
          context={auditContext.Warning}
          resource="therapy_id"
          resourceValue={therapy.id}
        />
        <Audit
          iconType="warning"
          auditRules={[THERAPY_SHOULD_HAVE_START_DATE]}
          context={auditContext.Warning}
          resource="therapy_id"
          resourceValue={therapy.id}
        />
        {!!discontinuedDate && (
          <Grid item xs>
            <DetailField
              fieldName="Discontinued Date"
              field={`${convertToArborDate(discontinuedDate, true).getUtcDate(true) || '-'}`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TherapyAdministration);
