import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { IconButton, TableCell, TableRow, Typography, Box, Tooltip } from '@mui/material';
import { NumberUtils } from 'utils/number-utils';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { IHeaderCell } from './types';
import { IndexType } from '../../../interfaces/IndexType';
import { UseStyles } from './base-table.styles';

interface IBaseRowProps<TParent, TChild> {
  actionsPermitted: boolean;
  enableParentCheckboxes?: boolean;
  enableChildCheckboxes?: boolean;
  headerCells: (IHeaderCell<TParent, TChild> | undefined)[];
  parent: TParent;
  children?: TChild[];
  index: number;
  triggerExpand: boolean;
  checkedItems: Record<IndexType, IndexType[]>;
  sortFn: (a: TChild, b: TChild) => number;
  parentPkSelector: (parent: TParent) => IndexType;
  childPkSelector: (child: TChild) => IndexType;
  addToChecked: (parentPk: IndexType, childPk: IndexType) => void;
  removeFromChecked: (parentPk: IndexType, childPk: IndexType) => void;
  classesOverrides?: {
    lastCell?: string;
    customChildRow?: string;
  };
  customChildrenComponent?: (parent: TParent) => JSX.Element | null;
  qaId?: string;
}

export const BaseRow = <TParent extends { children: TChild[] }, TChild>(
  props: IBaseRowProps<TParent, TChild>,
): JSX.Element => {
  const qaId = props.qaId || 'application-manager.gtd';
  const getQaId = buildQaId(`${qaId}.base-table.row`, '.');
  const classes: any = UseStyles();

  // #region useState
  const [open, setOpen] = React.useState<boolean>(false);
  // #endregion

  // #region helper functions
  const getPks = (child: TChild) => {
    const parentPk = props.parentPkSelector(props.parent);
    const childPk = props.childPkSelector(child);
    return { parent: parentPk, child: childPk };
  };

  const isChecked = (child: TChild): boolean => {
    const pks = getPks(child);
    return Boolean(
      props.checkedItems[pks.parent] && props.checkedItems[pks.parent].includes(pks.child),
    );
  };

  const onRowClick = (child: TChild): void => {
    const pks = getPks(child);
    if (props.actionsPermitted) {
      if (isChecked(child)) {
        props.removeFromChecked(pks.parent, pks.child);
      } else {
        props.addToChecked(pks.parent, pks.child);
      }
    }
  };

  const formatChild = (child: TChild, cell: IHeaderCell<TParent, TChild>): string => {
    if (cell.childCellValueOverride) {
      return cell.childCellValueOverride(child);
    }

    if (cell.childKey) {
      if (cell.childValueFormatter != null) {
        return cell.childValueFormatter(child[cell.childKey]);
      }
      return child[cell.childKey] as unknown as string;
    }
    return '';
  };

  const formatParent = (parent: TParent, cell: IHeaderCell<TParent, TChild>): string | null => {
    if (cell.parentCellValueOverride) {
      return cell.parentCellValueOverride(parent);
    }

    if (cell.parentKey) {
      const formatter = cell.parentValueFormatter || cell.childValueFormatter;
      if (formatter != null) {
        return formatter(parent[cell.parentKey], parent);
      }
      return parent[cell.parentKey] as unknown as string;
    }
    return null;
  };

  // #endregion

  // #region useMemo
  const isEven = React.useMemo<boolean>(() => {
    return NumberUtils.isEven(props.index);
  }, [props.index]);
  // #endregion

  // #region useEffect
  React.useEffect(() => {
    if (props.triggerExpand) {
      setOpen(true);
    }
  }, [props.triggerExpand]);
  // #endregion

  // #region renders
  const renderParentRow = (): JSX.Element => {
    return (
      <TableRow
        data-qa-id={getQaId('parent.row')}
        className={cx(classes.parentRow, {
          [classes.evenParent]: isEven,
          [classes.oddParent]: !isEven,
        })}
      >
        {props.actionsPermitted && props.enableParentCheckboxes ? (
          <TableCell className={classes.tableCell} />
        ) : null}
        {props.headerCells.map((cell, index) => {
          if (cell == null) {
            return null;
          }

          const cellContent = formatParent(props.parent, cell);
          return (
            <TableCell
              style={{ maxWidth: cell.parentMaxWidth }}
              key={(cell.childKey as string) || (cell.parentKey as string)}
              className={cx(
                classes.tableCell,
                index === props.headerCells.length - 1
                  ? props.classesOverrides?.lastCell
                  : undefined,
                {
                  [classes.tableCellAction]: props.actionsPermitted && cell.parentCellClick != null,
                },
              )}
              onClick={event => {
                if (props.actionsPermitted && cell.parentCellClick != null) {
                  cell.parentCellClick(event, props.parent);
                } else {
                  setOpen(!open);
                }
              }}
            >
              {/* If it's the first one, render the expand thing, or give space if no children */}
              {index === 0 ? (
                props.children == null ? (
                  cell.hideLeftPadding === true ? null : (
                    <Box pl={3.75} display="inline-block" />
                  )
                ) : (
                  <IconButton
                    size="small"
                    disableFocusRipple
                    disableRipple
                    className={classes.expandButton}
                  >
                    {props.children.length > 0 ? (
                      open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    ) : null}
                  </IconButton>
                )
              ) : null}
              {/* If the cell has a parent key, render the value from the parent */}
              {cell.parentKey ? (
                <ConditionalWrapper
                  condition={cell.showParentTooltip?.(cellContent) === true}
                  wrapper={child => <Tooltip title={`${cellContent}`}>{child}</Tooltip>}
                >
                  <Typography
                    display={
                      cell.parentMaxWidth != null && !cell.parentDisplayInline ? 'block' : 'inline'
                    }
                    noWrap={cell.parentMaxWidth != null}
                  >
                    {cellContent}
                  </Typography>
                </ConditionalWrapper>
              ) : null}
              {cell.parentComponent != null ? cell.parentComponent(props.parent, open) : null}
              {/* If the cell has a parent cell action, show the dropdown icon thing */}
              {cell.parentCellClick != null && props.actionsPermitted ? (
                <ArrowDropDownIcon className={classes.tableCellActionIcon} />
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderChildrenRows = (): JSX.Element | null => {
    if (props.customChildrenComponent) {
      const childKey = props.parentPkSelector(props.parent);
      return (
        <TableRow
          data-qa-id={getQaId(`child-row.${childKey}`)}
          className={classes.childRow}
          key={childKey}
        >
          <TableCell
            className={cx(classes.tableCell, classes.tableCellCheckbox)}
            colSpan={props.headerCells.length}
          >
            {props.customChildrenComponent(props.parent)}
          </TableCell>
        </TableRow>
      );
    }
    if (props.children == null) {
      return null;
    }
    return (
      <>
        {props.children
          .sort((a, b) => props.sortFn(b, a))
          .map((child, index) => {
            const childKey = props.childPkSelector(child);
            return (
              <TableRow
                data-qa-id={getQaId(`child-row.${childKey}`)}
                className={classes.childRow}
                key={childKey}
              >
                {props.actionsPermitted && props.enableChildCheckboxes ? (
                  <TableCell className={cx(classes.tableCell, classes.tableCellCheckbox)}>
                    <ArborCheckbox
                      className={classes.rowCheckbox}
                      checked={isChecked(child)}
                      onChange={event => {
                        onRowClick(child);
                      }}
                    />
                  </TableCell>
                ) : null}
                {props.headerCells.map((cell, index) => {
                  if (cell == null) {
                    return null;
                  }
                  // If first cell, render the checkbox
                  return cell.hideChildCol === true ? null : (
                    <TableCell
                      colSpan={cell.childColSpan}
                      className={cx(
                        classes.tableCell,
                        index === props.headerCells.length - 1
                          ? props.classesOverrides?.lastCell
                          : undefined,
                        {
                          [classes.tableCellAction]:
                            props.actionsPermitted && cell.childCellClick != null,
                          [classes.childFirstCell]: index === 0,
                        },
                        props.classesOverrides?.customChildRow ?? undefined,
                      )}
                      key={cell.childKey as string}
                      onClick={event => {
                        if (props.actionsPermitted && cell.childCellClick != null) {
                          cell.childCellClick(event, props.parent, child);
                        } else {
                          onRowClick(child);
                        }
                      }}
                    >
                      {cell.childComponent != null ? (
                        cell.childComponent(props.parent, child)
                      ) : (
                        <Typography display="inline">{formatChild(child, cell)}</Typography>
                      )}
                      {cell.childCellClick != null && props.actionsPermitted ? (
                        <ArrowDropDownIcon className={classes.tableCellActionIcon} />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </>
    );
  };
  // #endregion

  return (
    <>
      {renderParentRow()}
      {open && props.children ? renderChildrenRows() : null}
    </>
  );
};
