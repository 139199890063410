import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Modal, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from '@mui/styles';
import { getModalStyle } from 'services/utils/styles-service';
import { updatePatientPreferences } from 'actions/action-patient';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { compose } from 'recompose';
import cx from 'classnames';
import { renderWelcomeKitField } from 'containers/patient/demographics/preference-form';
import { required } from 'components/form/validation/validation';
import { IPatient } from 'interfaces/redux/IPatient';
import { styles } from '../../patient-overview/patient-overview-styles';

const FORM_ID = 'welcome-kit-modal';

interface IFormProps {
  welcome_kit_checkbox: number;
}

interface IStateProps {
  initialValues: Partial<IFormProps>;
  formValues: Partial<IFormProps>;
  patient: Partial<IPatient>;
}

interface IInjectedProps {
  updatePatientPreferences(
    payload: { welcome_kit: number; patient_id: number | undefined },
    patient: Partial<IPatient>,
    preferredContact: unknown,
    preferredContactItem: unknown,
  ): void;
}

interface IProps extends WithStyles<typeof styles> {
  visible: boolean;
  onModalClose: () => void;
}

type Props = IProps & IStateProps & IFormProps & IInjectedProps;

const ModalWelcomeKit: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  const handleSubmit = (values: any) => {
    const payload = {
      patient_id: props.patient.id,
      welcome_kit: Number(values.welcome_kit_checkbox),
    };

    props.updatePatientPreferences(payload, props.patient, null, null);
    props.onModalClose();
  };

  return (
    <Modal open={!!props.visible}>
      <form>
        <Grid
          spacing={2}
          container
          style={getModalStyle()}
          className={cx([classes.genericModal, classes.editWelcomeKitModal])}
        >
          <Grid item xs={12}>
            <Typography className={classes.heading}>Edit Welcome Kit Preference</Typography>
          </Grid>
          <Grid item xs={12} className={cx([classes.lastRow, classes.row])}>
            {renderWelcomeKitField({
              name: 'welcome_kit_checkbox',
              validationRules: [required],
              label: 'Welcome Kit/Patient Educational materials received',
            })}
          </Grid>
          <Grid item xs={12}>
            <ConfirmationPanel
              // eslint-disable-next-line
              disableSubmit={!(props.formValues && props.formValues['welcome_kit_checkbox'])}
              handleSubmit={() => {
                handleSubmit(props.formValues);
              }}
              handleCancel={() => {
                props.onModalClose();
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  const { patient } = state;

  const initialValues = {
    welcome_kit_checkbox: patient.welcome_kit,
  };

  const formValues = getFormValues(FORM_ID)(state) || initialValues;

  return { formValues, initialValues, patient, enableReinitialize: true, form: FORM_ID };
};

export default compose<Props, Props>(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { updatePatientPreferences }),
  reduxForm({}),
)(ModalWelcomeKit);
