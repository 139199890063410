/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { buildQaId } from 'utils/build-qa-id';
import { compose } from 'recompose';
import { InjectedFormProps, reduxForm, getFormValues, FieldArray, change } from 'redux-form';
import { getModalStyle } from 'services/utils/styles-service';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { cardExpirationValidation, length, required } from 'components/form/validation/validation';
import { EXPIRATION_DATE_FORMAT, PATIENT_RX_DELIVERY_PREFERENCES } from 'constants/index';
import { renderDropdown, renderNumberMaskedField } from 'components/form/field/redux-field';
import { connect, useSelector, useDispatch } from 'react-redux';
import { IAddPaymentMethodFields } from 'containers/add-payment-method/types';
import { updatePatientPreferences } from 'actions/action-patient';
import {
  addNewPaymentMethod,
  renderPaymentMethods,
} from 'containers/patient/demographics/preference-form';
import { TypedField } from 'utils/types-util';
import { EditAdd } from 'components/icons/icons';
import { styles as preferenceFormStyles } from 'containers/patient/demographics/patient-demographics-styles';
import { styles } from '../../components/add-address-modal/add-address-modal.styles';
import { useAddPaymentMethod } from './use-payment-methods';

const FORM_ID = 'add-payment-method-modal';

export enum ViewModeEnum {
  PatientOverview = 'patient-overiew',
  FillCoordination = 'fc',
}

const Field = TypedField<
  IAddPaymentMethodFields,
  {
    maskFormat?: any;
    allowEmptyFormatting?: boolean;
    fields?: { label: string; value: any }[];
    onValueChange?(x: any): void;
    maskCharacter?: string[];
  }
>();

interface IAddPaymentMethodModalProps
  extends WithStyles<typeof styles>,
    InjectedFormProps<IAddPaymentMethodFields> {
  initialValues: Partial<IAddPaymentMethodFields>;
  formValues: IAddPaymentMethodFields;
}
const qaIdBuilder = buildQaId('add-payment-method-modal');
const fourDigitCreditCardValidation = length(
  4,
  PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.ERRORS.LENGTH_4,
);

const AddPaymentMethodModal = (props: any) => {
  const { classes, formValues, form, submitting, untouch, handleSubmit } = props;

  const state = useAddPaymentMethod({ formName: form });
  const { viewMode } = state;

  const dispatch = useDispatch();

  const handleEditMultiplePayments = (event: any) => {
    const paymentMethodsToSubmit =
      // @ts-ignore
      formValues.payment_methods.map(({ updated, ...rest }) => rest) ?? [];

    const payload = {
      patient_id: props.patient.id,
      payment_methods: paymentMethodsToSubmit,
    };

    state.onCancel();
    dispatch(updatePatientPreferences(payload, props.patient, null, null));
  };

  return (
    <Modal open={state.isModalVisible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder('form')} autoComplete="off">
        <div style={getModalStyle()} className={classes.addModal}>
          {/* <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid> */}
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              {viewMode !== ViewModeEnum.PatientOverview
                ? 'Add New Payment Method'
                : 'Edit Payment Method'}
            </Typography>
          </Grid>
          {viewMode === ViewModeEnum.FillCoordination && (
            <>
              <Grid container className={classes.row}>
                <Grid
                  item
                  xs={4}
                  data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.CARD_NUM}
                >
                  <Field
                    name="add_payment_method_card_number"
                    validate={[required, fourDigitCreditCardValidation]}
                    component={renderNumberMaskedField}
                    maskFormat={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELD_MASK}
                    label={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.LAST_4}
                    allowEmptyFormatting
                    onChange={(event: any) => {
                      event.preventDefault();
                    }}
                    onBlur={(event: any) => {
                      event.preventDefault();
                    }}
                    onValueChange={({ value }: any) =>
                      dispatch(change(FORM_ID, 'add_payment_method_card_number', value))
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.EXPIRATION}
                >
                  <Field
                    // eslint-disable-next-line max-len
                    name="add_payment_method_expiration_date"
                    label={`${PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.EXPIRATION_DATE} *`}
                    component={renderNumberMaskedField}
                    placeholder={EXPIRATION_DATE_FORMAT}
                    allowEmptyFormatting
                    maskFormat="##/####"
                    maskCharacter={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                    validate={[cardExpirationValidation, required]}
                    onChange={(event: any) => {
                      event.preventDefault();
                    }}
                    onBlur={(event: any) => {
                      event.preventDefault();
                    }}
                    onValueChange={({ formattedValue }: any) =>
                      dispatch(
                        change(FORM_ID, 'add_payment_method_expiration_date', formattedValue),
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.TYPE}
                >
                  <Field
                    name="add_payment_method_type"
                    validate={[required]}
                    label={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.PAYMENT_TYPE}
                    component={renderDropdown}
                    fields={state.paymentMethodTypeOptions}
                    onChange={() => {
                      dispatch(change(FORM_ID, 'add_payment_method_therapy_associated', null));
                      untouch('add_payment_method_therapy_associated');
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                {formValues && formValues.add_payment_method_type === 4 ? (
                  <Grid item xs={4}>
                    <Field
                      name="add_payment_method_therapy_associated_id"
                      validate={[required]}
                      label={
                        PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.THERAPY_ASSOCIATED
                      }
                      component={renderDropdown}
                      fields={state.therapyAssociatedOptions}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
          {viewMode === ViewModeEnum.PatientOverview && (
            <>
              <Grid item xs={12}>
                <FieldArray
                  name="payment_methods"
                  component={renderPaymentMethods}
                  // eslint-disable-next-line react/destructuring-assignment
                  formArray={props.array}
                  formValues={props.formValues}
                  paymentMethodTypes={props.paymentMethodTypes}
                  // @ts-ignore
                  ownChangeFunction={(fieldName: string, fieldValue: any) => {
                    dispatch(change(FORM_ID, fieldName, fieldValue));
                  }}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={() => addNewPaymentMethod(props)}
                    data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.ADD_BUTTON}
                  >
                    <EditAdd />
                    <Typography className={classes.addPaymentMethodButtonText}>
                      {PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.ADD_BUTTON}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={
                viewMode === ViewModeEnum.FillCoordination
                  ? handleSubmit(state.onSubmit)
                  : (event: any) => handleEditMultiplePayments(event)
              }
              handleCancel={state.onCancel}
              disableSubmit={submitting}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

const originalInitialValues: IAddPaymentMethodFields = {
  add_payment_method_card_number: null,
  add_payment_method_type: null,
  add_payment_method_expiration_date: null,
  add_payment_method_therapy_associated_id: null,
};

function mapStateToProps(state: any) {
  const {
    patient,
    lookups: { paymentMethodTypes },
  } = state;

  const initialValues = { payment_methods: patient?.payment_methods || [] };
  const formValues = getFormValues(FORM_ID)(state) || initialValues;

  return {
    patient,
    form: FORM_ID,
    formValues: { ...originalInitialValues, ...formValues },
    initialValues: { ...originalInitialValues, ...initialValues },
    paymentMethodTypes,
    enableReinitialize: true,
  };
}

export default compose<IAddPaymentMethodModalProps, {}>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({}),
)(AddPaymentMethodModal);
