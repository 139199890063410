import React, { Component } from 'react';
import { Button, Grid, Divider, Typography, FormHelperText } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import withStyles from '@mui/styles/withStyles';
import { EditPencil } from 'components/icons/icons';
import DetailField from 'components/form/field/field';
import { numberToDollars } from 'services/utils/formatting';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  foundationTypeList,
  internalFoundationTypeList,
  externalFoundationTypeList,
  noteTagTypes,
} from 'constants/lists';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import DocumentDisplay from 'components/document/document-display';
import { getDocumentsForDisplay } from 'services/utils/document-service';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { isEmpty } from 'lodash';
import { styles } from '../../task-detail-styles';
import EditFoundation from './edit-foundation';
import { FINANCIAL } from '../../../../constants';
import { linkFinancialAssistanceTask } from '../../../../actions/action-financials';

const tagType = noteTagTypes.find(tag => tag.label === FINANCIAL);

class FoundationDetail extends Component {
  constructor(props) {
    super(props);
    // Warning: for the next dev who work on this, in this component we have two displayEdit variables,
    // one that comes from props, and the real one we use to render which is on the state, based upon the one from props
    this.state = {
      displayEdit: props.displayEdit || false,
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }

  componentDidMount() {
    const { editItemMap, financialAssistance } = this.props || {};
    const { id } = financialAssistance || {};
    if (editItemMap && editItemMap[id]) {
      this.handleEdit();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      financialAssistance: { id },
      editItemMap,
    } = this.props;
    if (editItemMap && prevProps.editItemMap[id] !== editItemMap[id]) {
      this.handleEdit();
    }
    if (
      this.props.changeDisplayEditOnChangeProps &&
      prevProps.displayEdit !== this.props.displayEdit
    ) {
      this.setState(_ => ({
        displayEdit: this.props.displayEdit,
      }));
    }
  }

  handleEdit() {
    this.setState(prevState => ({
      displayEdit: !prevState.displayEdit,
    }));
  }

  handleLink() {
    const { financialAssistance, currentTaskId } = this.props;
    this.props.linkFinancialAssistanceTask(currentTaskId, financialAssistance.task_fa_id);
  }

  onCancel = () => {
    if (this.props.externalEdit) {
      this.props.handleEditClose();
    } else {
      this.handleEdit();
    }
  };

  render() {
    const {
      classes,
      financialAssistance,
      type,
      uploadedDocuments,
      therapies,
      noEdit,
      showLinkButton,
      linked_fa,
      currentTaskId,
      externalEdit,
    } = this.props;

    const {
      id,
      status,
      award_amount: awardAmount,
      foundation_name: foundationName,
      spend_down: spendDown,
      group_id: group,
      phone_number: phoneNumber,
      expiration_date: expDate,
      remaining_balance: remainingBalance,
      bin,
      pcn,
      member_id: memberId,
      financial_assistance_type: financialAssistanceType,
      copay,
      created,
      expiration_date,
    } = financialAssistance;

    const [linked] = linked_fa || [];
    const alreadyLink = linked?.id === currentTaskId;

    const foundationProgram =
      financialAssistance?.foundation_program || financialAssistance?.program_name;

    const { displayEdit } = this.state;

    const drugName = therapies[financialAssistance.therapy_id]
      ? therapies[financialAssistance.therapy_id].drug_name
      : undefined;

    return (
      <div>
        <Grid container>
          {!displayEdit && !noEdit && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={3}>
                <DocumentDisplay
                  taskIdResourceIds={[{ tagTypeId: tagType.value, resourceId: id }]}
                  tagTypeId={tagType.value}
                  resourceId={id}
                  drugName={drugName}
                  tagTypeLabel={financialAssistance.financial_assistance_type_category}
                  documents={getDocumentsForDisplay(uploadedDocuments.documents, id, tagType.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container alignItems="center">
            {!displayEdit && (
              <Grid item xs={11}>
                <Grid container>
                  {!!type && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Type"
                        field={`${
                          [
                            ...foundationTypeList,
                            ...internalFoundationTypeList,
                            ...externalFoundationTypeList,
                          ].find(x => x.id === financialAssistanceType)?.label || ''
                        }`}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3} className={classes.textContainer}>
                    <DetailField
                      fieldName="Status"
                      field={
                        status === 0 || convertToArborDate(expiration_date, true).isBeforeToday()
                          ? 'Inactive'
                          : 'Active'
                      }
                    />
                  </Grid>
                  {!!foundationName && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Foundation Name" field={foundationName} />
                    </Grid>
                  )}
                  {!!awardAmount && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Award Amount" field={numberToDollars(awardAmount)} />
                    </Grid>
                  )}
                  {!!expDate && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Expiration Date"
                        field={convertToArborDate(expDate, true).getCustomerDate(true)}
                      />
                    </Grid>
                  )}
                  {!!spendDown && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Spend Down Required"
                        field={numberToDollars(spendDown)}
                      />
                    </Grid>
                  )}
                  {!!remainingBalance && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Remaining Balance"
                        field={numberToDollars(remainingBalance)}
                      />
                    </Grid>
                  )}
                  {!!phoneNumber && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <TalkdeskPhoneNumber
                        number={phoneNumber}
                        headerText="Program Phone Number"
                        tagInfo={{
                          tagTypeId: tagType.value,
                          tagResourceId: id,
                        }}
                      />
                    </Grid>
                  )}
                  {!!foundationProgram && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Program Name" field={foundationProgram} />
                    </Grid>
                  )}
                  {!!bin && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="BIN" field={bin} />
                    </Grid>
                  )}
                  {!!pcn && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="PCN" field={pcn} />
                    </Grid>
                  )}
                  {!!memberId && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Member ID" field={memberId} />
                    </Grid>
                  )}
                  {!!group && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Group" field={group} />
                    </Grid>
                  )}
                  {!!copay && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Copay" field={numberToDollars(copay)} variant="raw" />
                    </Grid>
                  )}
                  {!!created && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Start Date"
                        field={convertToArborDate(created).getUtcDate()}
                      />
                    </Grid>
                  )}
                  {!!expiration_date && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="End Date"
                        field={convertToArborDate(expiration_date).getUtcDate()}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {displayEdit && (
              <Grid item xs={11}>
                <EditFoundation
                  cancel={this.onCancel}
                  showDocumentForm={false}
                  fieldsToShow={this.props.fieldsToShow || []}
                  financialAssistance={financialAssistance}
                />
              </Grid>
            )}
            {!noEdit && !externalEdit && (
              <Grid item xs={1}>
                <Grid container justifyContent="flex-end">
                  <Button className={classes.button} onClick={this.handleEdit}>
                    <EditPencil />
                  </Button>
                </Grid>
              </Grid>
            )}
            {showLinkButton && !displayEdit && !alreadyLink && (
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={5}>
                    {showLinkButton && !noEdit && (
                      <FormHelperText className={classes.errorMessage}>
                        This is the original FA chain, if you are renewing, please update expiration
                        date
                      </FormHelperText>
                    )}
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={this.handleLink}
                    >
                      <LinkIcon sx={{ marginRight: 1 }} /> Link this foundation to therapy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!window.FEATURES.new_financials_view && <Divider />}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { uploadedDocuments, therapies, financials } = state;
  return { uploadedDocuments, therapies: therapies.data, linked_fa: financials?.has_linked };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    linkFinancialAssistanceTask,
  }),
)(FoundationDetail);
