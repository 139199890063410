import moment from 'moment';
import { convertToArborDate, getCustomerCurrentArborDate } from 'models/time/arbor-date';
import { convertMapToList } from 'reducers/helper-reducer';
import { assignKeys } from 'services/utils/demographic-service';
import {
  taskAbbreviationMap,
  CATEGORY_ARCHIVED,
  CATEGORY_DONE,
  TASK_STATUS_ARCHIVED,
  TASK_STATUS_DONE,
  TASK_STATUS_OUTSTANDING,
  TASK_STATUS_IN_PROGRESS,
  DUR,
  AR,
  INT,
  FC,
} from 'constants/index';
import { DEBOUNCE_IN_MS } from 'constants/config';
import debounce from 'debounce-promise';
import { parseLocation } from 'parse-address';
import { FillCycleReasonEnum } from 'interfaces/redux/ILookups';
import HTTP from '../http';
import { convertLinkWithDirection } from './reducer-service';

const MIN_DATE = '1969-12-31';

export const taskTypeToPropName = {
  CSL: 'counseling_list',
  INT: 'intervention_list',
  OR: 'outreach_list',
  PA: 'prior_authorization',
  FA: 'financial_assistance',
  TPR: 'thirdPartyReferralList',
  MR: 'medicationReviewList',
  FC: 'fill_coordination',
  FDC: 'fillDeliveryConfirmationList',
  DC: 'dataCollectList',
  QRE: 'incidentList',
  RS: 'riskStratification',
};

// eslint-disable-next-line
export const getTaskStatuses = taskStatuses => {
  if (taskStatuses && taskStatuses.length > 0) {
    return taskStatuses.reduce((result, taskStatus) => {
      const { status } = taskStatus;
      if (!result.includes(status)) {
        result.push(status);
      }
      return result;
    }, []);
  }
  return [];
};

// eslint-disable-next-line
export const getTaskStatusId = (taskStatuses, status, reason) =>
  taskStatuses.find(taskStatus => {
    if (reason) {
      return taskStatus.status === status && taskStatuses.reason === reason;
    }
    return taskStatus.status === status;
  }).id;

export const getStatusNameByStatusId = (statusId, statuses) => {
  if (!statusId || !statuses) {
    return null;
  }
  const flatstatus = Object.keys(statuses).reduce((acc, k) => [...acc, ...statuses[k]], []);
  const statusObj = flatstatus.find(s => s.id === statusId);
  return statusObj ? statusObj.status : null;
};

export const getStatusByStatusId = (statusId, statusesArray) => {
  if (!statusId || !statusesArray) {
    return null;
  }
  const statusObj = statusesArray.find(s => s.id === statusId);
  return statusObj ? statusObj.status : null;
};

export const getStatusReasonByStatusId = (statusId, statusesArray) => {
  if (!statusId || !statusesArray) {
    return null;
  }
  const statusObj = statusesArray.find(s => s.id === statusId);
  return statusObj && statusObj.reason ? statusObj.reason : null;
};

export const getStatusIdByStatusName = (statusName, statusesArray) => {
  if (!statusName || !statusesArray) {
    return null;
  }
  const statusObj = statusesArray.find(s => s.status === statusName);
  return statusObj ? statusObj.id : null;
};

export const getStatusCategoryByStatusId = (statusId, statusesArray) => {
  if (!statusId || !statusesArray) {
    return null;
  }
  const statusObj = statusesArray.find(s => s.id === statusId);
  return statusObj ? statusObj.category_name : null;
};

export const getStatusCategoryIdByStatusId = (statusId, statuses) => {
  if (!statusId || !statuses) {
    return null;
  }
  const flatstatus = Object.keys(statuses).reduce((acc, k) => [...acc, ...statuses[k]], []);
  const statusObj = flatstatus.find(s => s.id === statusId);
  return statusObj ? statusObj.category_id : null;
};

export const getLookupValById = (statusId, statusesArray, valName) => {
  if (!statusId || !statusesArray) {
    return null;
  }
  const statusObj = statusesArray.find(s => s.id === statusId);
  return statusObj ? statusObj[valName] : null;
};

export const getReasonsByStatus = (statusArray, desiredStatus) =>
  statusArray
    ?.filter(s => s.status === desiredStatus)
    ?.map(s => ({ label: s.reason, value: s.id })); // eslint-disable-line

export const getInProgressStatus = () => [TASK_STATUS_OUTSTANDING, TASK_STATUS_IN_PROGRESS];
export const getCompleteStatus = () => [TASK_STATUS_DONE, TASK_STATUS_ARCHIVED];
export const getArchivedStatus = getCompleteStatus;

export function stopPropagation(e) {
  e.stopPropagation(); // prevent therapy summary panel expanding
}

export const isSatisfiedByServiceGroupFilter = (serviceGroup, serviceGroups) => {
  if (!serviceGroup) {
    return true; // if task is not assigned to a specific service group, then it won't be filtered
  }

  if (serviceGroups && serviceGroups.length > 0) {
    return serviceGroups.includes(serviceGroup);
  }

  return true;
};

export const reduceStatusesWithSubstatusesToArray = (statuses, fieldName, type) => {
  const retArr = [];
  let statusesArr = statuses;
  if (type) {
    statusesArr = statusesArr.filter(s => s.type_id === type && s.reason_subtype);
  }
  const mappedStatuses = statusesArr.map(s => s[fieldName]);
  // eslint-disable-next-line
  mappedStatuses.map(status => {
    if (!retArr.includes(status)) {
      retArr.push(status);
    }
  });
  if (!retArr[0]) {
    return [];
  }
  return retArr.map(r => ({
    value: r,
    label: r,
  }));
};

export const displayPhone = phone => {
  if (phone) {
    if (phone.use) {
      return `${phone.use} - ${phone.value}`;
    }
    return `${phone.value}`;
  }
  return 'Phone Not Found';
};

export const getFilteredList = (
  list,
  includeDeleted, // eslint-disable-line
) => (list ? list.filter(item => includeDeleted || !item.deleted) : []);

export const getPhoneList = (patient, includeDeleted) => {
  const filteredList = getFilteredList(patient.phones, includeDeleted);
  return filteredList.length
    ? filteredList.map((phone, index) => ({
        label: displayPhone(phone),
        value: phone.rank || index + 1,
      }))
    : [{ label: 'No Phones in Arbor for this Patient', value: null }];
};

export const displayPhoneById = (phoneId, phonesArr) => {
  if (!phonesArr || phonesArr.length === 0) {
    return null;
  }
  const phone = phonesArr.find(phoneItem => phoneItem.rank === parseInt(phoneId, 10));
  return displayPhone(phone);
};

export const convertFilteredAddresses = addresses => {
  if (addresses) {
    addresses = addresses.map(address => ({
      ...address,
      state: address.state ? address.state.toUpperCase() : '',
    }));
  }
  return getFilteredList(assignKeys(addresses));
};

export const getStateForDisplay = address => {
  let state = '';
  if (address) {
    const parsedObj = parseLocation(
      `${address.line1} ${address.line2 || ''} ${address.city} ${address.state} ${address.zip}`,
    );
    if (parsedObj && parsedObj.state) {
      state = parsedObj.state.toUpperCase();
    } else if (address.state) {
      state = address.state.toUpperCase();
    }
  }
  return state;
};

export const getAddressForDisplay = (address, excludeUse, providerOfficeName) => {
  let result = '';
  let state = '';
  if (address) {
    state = getStateForDisplay(address);
  }
  if (!address || (!!address && !address.line1)) {
    return result;
  }
  if (!excludeUse && address.use) {
    result += `${address.use} - `;
  }
  if (providerOfficeName) {
    result = `${result} ${providerOfficeName} -`;
  }
  result += address.line1;
  if (address.line2) {
    result += `, ${address.line2}`;
  }
  if (address.city) {
    result += `, ${address.city}`;
  }
  if (address.state) {
    result += `, ${state}`;
  }
  if (address.zip) {
    result += `, ${address.zip}`;
  }
  return result;
};

export const displayAddress = address => {
  if (address) {
    return getAddressForDisplay(address);
  }
  return 'Address Not Found';
};

export const getAddressList = (patient, includeDeleted) => {
  const filteredList = getFilteredList(patient.addresses, includeDeleted);
  return filteredList.length
    ? filteredList.map((address, index) => ({
        label: displayAddress(address),
        value: address.rank || index + 1,
      }))
    : [{ label: 'No Addresses in Arbor for this Patient', value: null }];
};

export const displayAddressById = (addressId, addressArr) => {
  if (!addressArr || addressArr.length === 0) {
    return null;
  }
  const address = addressArr.find(addressInArr => addressInArr.rank === parseInt(addressId, 10));
  return displayAddress(address);
};

export const findDefaultForDemographics = demographicArr => {
  const filteredList = getFilteredList(demographicArr);
  if (filteredList.length === 0) {
    return null;
  }
  return filteredList.sort((a, b) => a.rank - b.rank)[0].rank;
};

export const getInitialServiceGroupForReactSelect = (serviceGroupId, serviceGroups) =>
  serviceGroups
    .map(serviceGroup => ({
      label: serviceGroup.display_name,
      value: serviceGroup.id,
    }))
    .find(foundServiceGroup => foundServiceGroup.value === serviceGroupId);

export const getInitialTPRHUBForReactSelect = (tprHubId, tprHubs) =>
  tprHubs
    .map(hub => ({
      label: hub.label,
      value: hub.id,
    }))
    .find(foundServiceGroup => foundServiceGroup.value === tprHubId);

export const getInitialManagingClinicForReactSelect = (
  managingClinicId,
  managingClinics,
  clinicDefinedInTherapy,
) => {
  const currentSelectionActive = managingClinics.find(clinic => clinic.id === managingClinicId);
  if (currentSelectionActive) {
    return {
      label: currentSelectionActive.name,
      value: currentSelectionActive.id,
      npi: currentSelectionActive.npi,
    };
  }
  return clinicDefinedInTherapy;
};

export const includeExistingSelectionInList = (managingClinics, therapy) => {
  if (managingClinics.find(clinic => clinic.id === therapy?.managing_clinic_id)) {
    return managingClinics;
  }
  return managingClinics
    .concat({
      id: therapy?.managing_clinic_id,
      name: therapy?.managing_clinic_name,
      npi: therapy?.managing_clinic_npi,
    })
    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};

export const getInitialPhoneForReactSelect = phonesArr => {
  const phoneId = findDefaultForDemographics(phonesArr);

  return phonesArr
    .map(phone => ({
      label: displayPhoneById(phone.rank, phonesArr),
      value: phone.rank,
    }))
    .find(s => s.value === phoneId);
};

export const getInitialAddressForReactSelect = addressArr => {
  const addressId = findDefaultForDemographics(addressArr);
  return addressArr
    .map(address => ({
      label: displayAddressById(address.rank, addressArr),
      value: address.rank,
    }))
    .find(s => s.value === addressId);
};

export const getPhoneForReactSelect = (phonesArr, phoneId) =>
  phonesArr
    .map(phone => ({
      label: displayPhoneById(phone.rank, phonesArr),
      value: phone.rank,
    }))
    .find(s => s.value === parseInt(phoneId, 10));

export const getAddressForReactSelect = (addressArr, addressId) =>
  addressArr
    .map(address => ({
      label: displayAddressById(address.rank, addressArr),
      value: address.rank,
    }))
    .find(s => s.value === parseInt(addressId, 10));

export const followupDateChanged = (taskFollowup, payloadFollowup) => {
  if (!taskFollowup) return true;
  return taskFollowup.replace('T', ' ').substring(0, 16) !== payloadFollowup;
};

export const evaluateRecommendationForIntervention = recommendationId => {
  if (recommendationId === 6) {
    return 6001;
  }
  if (recommendationId === 7) {
    return 7001;
  }
  return false;
};

export const initializeFieldsVisibilityController = fields => {
  const willSectionDisplay = (
    props,
    sectionId, // eslint-disable-line
  ) => !fields[sectionId] || fields[sectionId].conditionEdit.call(null, props);

  const willControlDisplay = (props, controlId) => {
    let result = false;
    Object.keys(fields).forEach(sectionId => {
      result =
        result ||
        (willSectionDisplay(props, sectionId) && // eslint-disable-line
          fields[sectionId].controlIds.includes(controlId));
    });
    return result;
  };

  const clearControlsInPayload = (props, payload) => {
    Object.keys(payload).forEach(key => {
      if (!willControlDisplay(props, key)) {
        delete payload[key];
      }
    });
  };

  return {
    willSectionDisplay,
    willControlDisplay,
    clearControlsInPayload,
  };
};

// Even though the UI rendered therapies first, this method
// returns the other in which they should be prioritized
// with therapies at the end
export const getTaskTypesInOrder = taskCounts => {
  const taskTypes = Object.keys(taskCounts);
  return [...taskTypes.filter(it => it !== 'therapies'), 'therapies'];
};

export const getExpanded = (taskStatusId, statuses) => {
  const statusCategoryId = getStatusCategoryIdByStatusId(taskStatusId, statuses);
  return getInProgressStatus().indexOf(statusCategoryId) !== -1;
};

export const getPendingTasks = tasks => {
  const inProgressStatus = getInProgressStatus();
  return tasks.filter(task => inProgressStatus.includes(task.status_category_id));
};

export const getChecked = (taskStatusId, statuses) => {
  const statusCategoryId = getStatusCategoryIdByStatusId(taskStatusId, statuses);
  return [...getCompleteStatus(), ...getArchivedStatus()].indexOf(statusCategoryId) !== -1;
};

export const getCombinedTasks = (tasks, includeType) =>
  Object.keys(taskTypeToPropName) // eslint-disable-line
    .reduce((acc, type) => {
      let currentTasks = tasks[taskTypeToPropName[type]] || [];
      if (includeType) {
        currentTasks = currentTasks.map(t => ({ ...t, taskType: type }));
      }
      Array.prototype.push.apply(acc, currentTasks);
      return acc;
    }, []);

export const getPatientLevelTasks = taskList =>
  taskList.filter(task => {
    if (
      task.taskType &&
      (task.taskType === DUR ||
        task.taskType === AR ||
        (task.taskType === INT && task.therapy_id === null))
    ) {
      return true;
    }
    return false;
  });

export const sortTaskListBy = (list, orderBy, order, mutate, defaultSort) => {
  const sorted = mutate ? list : [...list];

  const comparePatientNames = (taskA, taskB) => {
    if (taskA.taskType === FC) {
      // For now, only sort by patient name for FC
      return `${taskA.last_name}, ${taskA.first_name}`.localeCompare(
        `${taskB.last_name}, ${taskB.first_name}`,
      );
    }

    return `${taskA.taskType}${taskA.id}`.localeCompare(`${taskB.taskType}${taskB.id}`);
  };

  if (orderBy === 'is_needsby_per_protocol') {
    sorted.sort(a => (a.is_needsby_per_protocol ? -1 : 1));
  } else if (orderBy) {
    sorted.sort((a, b) => {
      const modifier = order === 'asc' ? 1 : -1;
      if (orderBy === 'followup_dt' || orderBy === 'needsby_date') {
        const dateA = a[orderBy] ? moment(a[orderBy]) : moment(MIN_DATE);
        const dateB = b[orderBy] ? moment(b[orderBy]) : moment(MIN_DATE);
        if (dateA.isSame(dateB, 'day')) {
          return comparePatientNames(a, b);
        }
        const comparison = dateA.isBefore(dateB) ? -1 : 1;

        return modifier * comparison;
      }
      const comparison = a[orderBy] < b[orderBy] ? -1 : 1;
      return modifier * comparison;
    });
  } else {
    sorted.sort((a, b) => {
      const modifier = order === 'asc' ? 1 : -1;
      const dateA = a[defaultSort] ? moment(a[defaultSort]) : moment(MIN_DATE);
      const dateB = b[defaultSort] ? moment(b[defaultSort]) : moment(MIN_DATE);
      if (dateA.isSame(dateB)) {
        return `${a.taskType}${a.id}`.localeCompare(`${b.taskType}${b.id}`);
      }
      const comparison = dateA.isBefore(dateB) ? -1 : 1;
      return modifier * comparison;
    });
  }
  return sorted;
};

export const findTaskById = (therapies, taskType, taskId) => {
  const therapyIds = Object.keys(therapies);
  for (let i = 0; i < therapyIds.length; i += 1) {
    const therapyId = therapyIds[i];
    const list = getCombinedTasks(therapies[therapyId], true);
    const item = (list || []).find(it => it.id === taskId && it.taskType === taskType);
    if (item) {
      return item;
    }
  }
  return null;
};

export const findTaskInTasks = (tasks, taskType, taskId) =>
  tasks.find(it => it.id === taskId && it.taskType === taskType);

export const getDaysSupplyForTask = (task, fillCycles) => {
  if (fillCycles[task.therapy_id]) {
    const fillCyclesObj = fillCycles[task.therapy_id];
    if (fillCyclesObj && fillCyclesObj[task.fill_cycle_number]) {
      const fillCycleForTask = fillCyclesObj[task.fill_cycle_number];
      return fillCycleForTask.days_supply;
    }
  }
  return null;
};

export const filterTasksByUniqueTherapy = tasks => {
  const result = [];
  tasks.forEach(task => {
    const existing = result.find(it => it.therapy_id === task.therapy_id);
    if (!existing) {
      result.push({
        ...task,
        taskIds: [task.id],
      });
    } else {
      existing.taskIds.push(task.id);
    }
  });
  return result;
};

export const filterTasksByUniqueTherapyAndFillCycle = tasks => {
  const result = [];
  tasks.forEach(task => {
    const existing = result.find(
      it => it.therapy_id === task.therapy_id && it.fill_cycle_number === task.fill_cycle_number,
    );
    if (!existing) {
      result.push({
        ...task,
        taskIds: [task.id],
      });
    } else {
      existing.taskIds.push(task.id);
    }
  });
  return result;
};

const filterByDateType = (tasks, from, to, dateType) =>
  tasks.filter(task => {
    const date = task[dateType];
    if (!date) {
      return !from;
    }
    const converted = convertToArborDate(date, true);
    if (!from) {
      return converted.dateObject.isBefore(to);
    }
    if (!to) {
      return converted.dateObject.isSameOrAfter(from);
    }
    return converted.dateObject.isBetween(from, to, null, '[)');
  });

export const filterTasksByStatus = (tasks, statuses) => {
  if (!tasks || !statuses) {
    return [];
  }

  const filteredTasks = Object.values(tasks);
  return filteredTasks.filter(task => statuses.includes(task.status_id));
};

export const hasTaskWithTypeAndNoneWithStatus = (tasks, statuses, type) => {
  if (!tasks || !statuses) {
    return [];
  }

  const filteredTasks = Object.values(tasks);
  const filteredTasksByType = filteredTasks.filter(task => task.taskType === type);
  if (filteredTasksByType.length === 0) {
    return true;
  }
  return filteredTasksByType.some(task => statuses.includes(task.status_id));
};

export const hasNoTasksOfTypeForTherapy = (tasks, therapy, type) => {
  if (!tasks) {
    return true;
  }

  const filteredTasks = Object.values(tasks);
  const filteredTasksByType = filteredTasks.filter(task => task.taskType === type);
  if (filteredTasksByType.length === 0) {
    return true;
  }
  const filteredTasksByTherapy = filteredTasksByType.filter(task => {
    const taskTherapyId = task.therapy_id || task.therapyInitialId;
    return taskTherapyId === therapy.id;
  });
  return filteredTasksByTherapy.length === 0;
};

export const groupTasksByDate = (tasks, dateType, format) => {
  const converted = getCustomerCurrentArborDate();
  const tomorrow = converted.clone().startOf('day').add(1, 'day');
  const dayAfterTomorrow = converted.clone().startOf('day').add(2, 'day');
  const nextWeekStart = converted.clone().startOf('day').add(1, 'week');
  const next2WeekStart = converted.clone().startOf('day').add(2, 'week');
  const nextMonthStart = converted.clone().startOf('day').add(1, 'month');
  const next6MonthStart = converted.clone().startOf('day').add(6, 'month');
  switch (format) {
    case 'taskTab':
      return [
        {
          label: 'This Week',
          tasks: filterByDateType(tasks, null, nextWeekStart, dateType),
        },
        {
          label: 'Next Week',
          tasks: filterByDateType(tasks, nextWeekStart, next2WeekStart, dateType),
        },
        {
          label: 'Next Month',
          tasks: filterByDateType(tasks, next2WeekStart, nextMonthStart, dateType),
        },
        {
          label: 'Next 6 Months',
          tasks: filterByDateType(tasks, nextMonthStart, next6MonthStart, dateType),
        },
        {
          label: 'Other',
          tasks: filterByDateType(tasks, next6MonthStart, null, dateType),
        },
      ];
    case 'taskSidebar':
      return [
        {
          label: 'Today',
          tasks: filterByDateType(tasks, null, tomorrow, dateType),
        },
        {
          label: 'Tomorrow',
          tasks: filterByDateType(tasks, tomorrow, dayAfterTomorrow, dateType),
        },
        {
          label: 'This Week',
          tasks: filterByDateType(tasks, dayAfterTomorrow, nextWeekStart, dateType),
        },
        {
          label: 'This Month',
          tasks: filterByDateType(tasks, nextWeekStart, nextMonthStart, dateType),
        },
        {
          label: 'Next 6 Months',
          tasks: filterByDateType(tasks, nextMonthStart, next6MonthStart, dateType),
        },
        {
          label: 'Other',
          tasks: filterByDateType(tasks, next6MonthStart, null, dateType),
        },
      ];
    default:
      return null;
  }
};

export const addTaskOldValues = (task, oldTask) =>
  Object.keys(task).reduce((acc, key) => {
    acc[`old_${key}`] = oldTask[key];
    return acc;
  }, task);

export const addTasksOldValues = (tasks, oldTasks) =>
  tasks.map(it => {
    const originalTask = oldTasks.find(ot => it.id === ot.id);
    return addTaskOldValues(it, originalTask);
  });

export const combineTaskAndLinks = (task, linkMap) => {
  const links = convertMapToList(linkMap).map(link => ({
    ...link,
    meta: JSON.parse(link.meta),
  }));
  const newTask = {
    ...task,
    links: links
      .map(link => {
        if (
          link.resource_one_id === task.id &&
          taskAbbreviationMap[link.resource_one_type] === task.taskType
        ) {
          return convertLinkWithDirection(link, false);
        }
        if (
          link.resource_two_id === task.id &&
          taskAbbreviationMap[link.resource_two_type] === task.taskType
        ) {
          return convertLinkWithDirection(link, true);
        }
        return null;
      })
      .filter(link => link),
  };
  return newTask;
};

export const areAllDrugsExcluded = drugs => drugs.every(drug => drug.is_excluded);

export const areAllDrugsValidated = drugs =>
  drugs.every(drug => drug.is_excluded || drug.therapy_id);

export const getTaskUniqueId = task => `${task.taskType}${task.id}`;

export const getTaskName = task =>
  task.type
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getTaskNameLine2 = (task, statuses) => {
  switch (task.taskType) {
    case 'INT':
      return [
        (statuses.intervention_categories.find(it => it.id === task.category_id) || {})
          .category_name || '',
        (statuses.intervention_types.find(it => it.id === task.type_id) || {}).type_name || '',
      ].join(' - ');
    case 'OR':
      return [
        (statuses.outreach_categories.find(it => it.id === task.category) || {}).name || '',
        (statuses.outreach_types.find(it => it.id === task.type_id) || {}).name || '',
      ].join(' - ');
    default:
      return '';
  }
};

export const getNeedsByDateOfTask = (fillCycles, task) => {
  const date =
    fillCycles && fillCycles[task.therapy_id] && fillCycles[task.therapy_id][task.fill_cycle_number]
      ? fillCycles[task.therapy_id][task.fill_cycle_number].needsby_date
      : null;
  return date ? convertToArborDate(date, true).getUtcDate(true) : '-';
};

export const isTaskCompleted = task =>
  [CATEGORY_DONE, CATEGORY_ARCHIVED].includes(task?.status_category_id);

export const sortWithOtherComparer = prop => (a, b) => b[prop] !== 'Other' ? 1 : -1;

export const getTherapyFillCycleReasonOptions = (lookupsTherapyFillCycleReasons, therapy) => {
  if (!lookupsTherapyFillCycleReasons) {
    return [];
  }

  const disableNewFC = therapy && 'fill_cycle' in therapy && therapy.fill_cycle === 1;
  const reasons = lookupsTherapyFillCycleReasons
    .filter(r => r.id !== FillCycleReasonEnum.MedSync)
    .map(r => ({
      label: r.description,
      value: r.id,
      disabled: r.id === 3 && disableNewFC,
      new: Boolean(r.new),
    }));
  return reasons;
};

export const fetchParentPaTaskNoDebounce = taskId => {
  return HTTP.get(`/tasks/pa/${taskId}/parent`, {});
};

export const fetchTask = async (taskType, taskId) => {
  const response = await HTTP.get(`/tasks/${taskType}/${taskId}`, {});
  const { task } = response.data;
  return !task || !task.length ? [] : task[0];
};

export const fetchParentPaTask = debounce(fetchParentPaTaskNoDebounce, DEBOUNCE_IN_MS);
