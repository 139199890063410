import React, { useEffect, useMemo, useState } from 'react';
import { Grid, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { IRemoveAllModalProps } from './types';
import { MedicationStatus } from '../../../../constants/enums';
import { getModalStyle } from '../../../../services/utils/styles-service';
import Divider from '../../../../components/divider';
import SingleValueCheckboxGroup from '../../../../components/form/field/single-field-checkbox-group/single-field-checkbox-group';
import ConfirmationPanel from '../../../../components/form/confirmation/confirmation-panel';
import { DUR_FETCH_BY_ID, REFRESH_MEDICATIONS } from '../../../../constants';
import { triggerInteractions } from '../../../../actions/action-drug-utilization-review';
import { notifySuccess } from '../../../../actions/action-notifications';
import HTTP from '../../../../services/http';
import { useLatestDur } from '../../../tasks/counseling/counseling-transition/forms/hooks';
import { styles } from '../patient-clinical-styles';

const MedicationRemoveAllModal: React.FC<IRemoveAllModalProps> = (
  props: IRemoveAllModalProps,
): JSX.Element => {
  const { overwriteGroups, classes, open, onClose, patient } = props;
  const [medListSearch, setMedListSearch] = useState('');
  const [checkedMedications, setCheckedMedications] = useState<number[]>([]);
  const dispatch = useDispatch();
  const durTask = useLatestDur();

  const medicationModalOptions = useMemo(() => {
    return overwriteGroups
      .filter(medication => medication.status_code === MedicationStatus.Active)
      .map(medication => ({
        label: `${medication.drug_name} ${medication.dosage_form} ${medication.strength} ${medication.strength_unit_of_measure}`,
        value: medication.id,
        disabled: !!medication.therapy_id,
      }))
      .filter(item => item.label.match(new RegExp(medListSearch, 'gi')));
  }, [overwriteGroups, medListSearch]);

  const filteredMedicationModalOptions = useMemo(() => {
    return medicationModalOptions.filter(item => item.label.match(new RegExp(medListSearch, 'gi')));
  }, [medicationModalOptions, medListSearch]);

  const totalMedicationForRemoval = useMemo(
    () => medicationModalOptions.filter(opt => !opt.disabled).length,
    [medicationModalOptions],
  );

  useEffect(() => {
    setCheckedMedications(
      medicationModalOptions.filter(opt => !opt.disabled).map(opt => opt.value),
    );
  }, [medicationModalOptions]);

  const removeMedications = async () => {
    if (!checkedMedications.length) {
      return;
    }

    const payload = checkedMedications
      .map(id => {
        const medication = overwriteGroups.find(m => m.id === id);
        if (!medication) {
          return undefined;
        }

        return {
          id: id,
          patient_id: medication.patient_id,
          therapy_id: medication.therapy ? medication.therapy.id : null,
          status_code: MedicationStatus.Discontinued,
        };
      })
      .filter(item => !!item);

    await HTTP.patch(`/patients/${patient?.id}/medications`, payload);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setMedListSearch('');
      }}
    >
      <Grid style={getModalStyle()} className={classes.removeAllModal}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {totalMedicationForRemoval > 0 ? (
              <Typography variant="h6" marginBottom={3}>
                Remove all {totalMedicationForRemoval} medications?
              </Typography>
            ) : (
              <Typography variant="h6" marginBottom={3}>
                No medications to remove
              </Typography>
            )}
            <Divider />
            <Typography variant="body1" marginTop={2}>
              Only unenrolled medication can be removed.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Search"
              variant="standard"
              onChange={e => {
                setMedListSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="medications"
              component={SingleValueCheckboxGroup}
              options={filteredMedicationModalOptions}
              layoutProps={{ xs: 12 }}
              formGroupProps={{ style: { padding: 0 } }}
              itemProps={overwriteGroups.reduce((itemProps: any, medication) => {
                itemProps[medication.id] = {
                  checked: checkedMedications.includes(medication.id),
                  disabled: !!medication.therapy_id,
                  onChange: () => {
                    const idx = checkedMedications.indexOf(medication.id);
                    if (idx !== -1) {
                      setCheckedMedications([
                        ...checkedMedications.slice(0, idx),
                        ...checkedMedications.slice(idx + 1, checkedMedications.length),
                      ]);
                    } else {
                      setCheckedMedications([...checkedMedications, medication.id]);
                    }
                  },
                };
                return itemProps;
              }, {})}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <ConfirmationPanel
              cancelButtonName="remove_all_medication_cancel_button"
              submitButtonName="remove_all_medication_submit_button"
              cancelButtonText="Cancel"
              submitButtonText="Remove"
              disableSubmit={checkedMedications.length === 0}
              handleCancel={() => {
                onClose();
              }}
              handleSubmit={async () => {
                await removeMedications();
                onClose();
                dispatch({ type: REFRESH_MEDICATIONS });
                dispatch(triggerInteractions(patient?.id));
                dispatch({ type: DUR_FETCH_BY_ID, payload: { id: durTask?.task?.id } });
                dispatch(
                  notifySuccess(`Removed #${checkedMedications.length} medications successfully!`),
                );
              }}
              buttonIdPrefix="remove_all_medication"
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default compose<any, any>(
  withRouter,
  withStyles(styles),
  reduxForm({ form: 'medication-remove-all-modal' }),
)(MedicationRemoveAllModal);
