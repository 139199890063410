import createStyles from '@mui/styles/createStyles';

export const styles = theme =>
  createStyles({
    patientOverviewContainer: {
      position: 'sticky',
      zIndex: 2,
      top: theme.layout.topBarHeight,
      backgroundColor: theme.palette.primary.white,
      width: '100%',
    },
    patientOverviewTaskListExpandedContainer: {
      width: '100%',
    },
    summaryContainer: {
      backgroundColor: theme.palette.primary.trellisLightBlue,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    possibleDuplicateProfilesContainer: {
      backgroundColor: theme.palette.primary.redWhite,
      fontSize: theme.spacing(2.25),
      padding: theme.spacing(1.25),
    },
    duplicateProfilesHeader: {
      color: theme.palette.primary.darkRed,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
    },
    duplicateProfilesHeaderText: {
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(1.625),
    },
    duplicateProfilesLink: {
      'marginLeft': theme.spacing(3.75),
      'paddingTop': theme.spacing(0.625),
      'fontSize': theme.spacing(1.625),
      'fontWeight': 400,
      'lineHeight': '17px',
      'color': theme.palette.primary.darkRed,
      '&:visited': {
        color: theme.palette.primary.darkRed,
      },
    },
    duplicateProfilesDetailsExpandButton: {
      'lineHeight': 'unset',
      'minWidth': 'unset',
      '& svg': {
        fill: theme.palette.primary.darkRed,
      },
    },
    reportIssueButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sidebarContainer: {
      paddingLeft: theme.spacing(1),
    },
    noteContainer: {
      fontSize: theme.font.smallFontSize,
      padding: 3,
      marginTop: 5,
      marginBottom: 2,
      backgroundColor: theme.palette.primary.white,
      borderRadius: 5,
      overflow: 'hidden',
      width: '100%',
      height: '95%',
      position: 'relative',
      cursor: 'pointer',
    },
    patientHeader: {
      marginBottom: '4px',
    },
    statusHeader: {
      marginLeft: '4px',
    },
    demographicsColumn: {
      paddingLeft: theme.spacing(1),
    },
    columnHorizontalDivider: {
      borderBottom: `1px solid ${theme.palette.primary.faintBorder}`,
    },
    verticalDividerLeft: {
      borderLeft: `1px solid ${theme.palette.primary.faintBorder}`,
    },
    verticalDividerLeft2: {
      paddingLeft: '10px',
    },
    summarySectionHeader: {
      color: theme.palette.primary.steelGrey,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: theme.font.extraSmallFontSize,
      paddingTop: theme.spacing(),
      width: '100%',
    },
    summarySectionHeaderNoPadding: {
      color: theme.palette.primary.steelGrey,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: theme.font.extraSmallFontSize,
      width: '100%',
    },
    summarySectionHeaderHorizontal: {
      color: theme.palette.primary.formLabel,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: theme.font.extraSmallFontSize,
      paddingRight: theme.spacing(1),
    },
    summaryEmphasis: {
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    patientName: {
      fontWeight: 'bold',
    },
    nickName: {
      fontWeight: 'bold',
    },
    importantText: {
      color: theme.palette.primary.darkRed,
    },
    boldText: {
      fontWeight: 'bold',
    },
    contactPhone: {
      marginTop: '5px',
    },
    translateIcon: {
      paddingLeft: '5px',
      width: '12px',
      height: '12px',
    },
    copyBox: {
      border: `1px dashed ${theme.palette.primary.formLabel}`,
    },
    copyBoxTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '6px',
    },
    copyBoxbutton: {
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      float: 'right',
    },
    actionIcon: {
      'height': '25px',
      'width': '25px',
      'padding': '5px',
      'cursor': 'pointer',
      'transform': 'translateX(-50%)',
      '& *': {
        fill: theme.palette.primary.trellisBlue,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 20,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 25,
      },
    },
    starIcon: {
      [theme.breakpoints.up('md')]: {
        marginRight: 15,
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 15,
      },
    },
    tabRoot: {
      '&.Mui-tabSelected': {
        color: theme.palette.navigation.secondary,
      },
      '&:focus': {
        color: theme.palette.primary.hover,
      },
      'opacity': 1,
    },
    secondaryTabRoot: {
      '&.Mui-tabSelected': {
        color: theme.palette.primary.hover,
      },
      '&:focus': {
        color: theme.palette.primary.hover,
      },
    },
    tabSelected: {},
    secondaryTabsIndicator: {
      backgroundColor: theme.palette.primary.hover,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    container: {
      display: 'flex',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    actionBar: {
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      'paddingTop': 5,
      '& > *': {
        padding: 0,
        marginBottom: 5,
      },
    },
    typographyTextSmall: {
      fontSize: 13,
    },
    typographyTextLarge: {
      fontSize: theme.font.largeFontSize,
    },
    labelPadding: {
      marginLeft: 2,
      marginBottom: 2,
    },
    allergyGrid: {
      overflow: 'hidden',
      maxHeight: 90,
    },
    allergyText: {
      marginLeft: 2,
      fontSize: 13,
      paddingRight: theme.spacing(1),
      marginBottom: 2,
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    },
    inlineTypeographyDemographicsLeft: {
      paddingRight: theme.spacing(1),
    },
    inlineTypeographyDemographics: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderLeft: `1px solid ${theme.palette.primary.faintBorder}`,
    },
    inlineTypeographyDemographicsRight: {
      paddingLeft: theme.spacing(1),
      borderLeft: `1px solid ${theme.palette.primary.faintBorder}`,
    },
    demographicsRowSpacing: {
      paddingBottom: theme.spacing(0.5),
    },
    appointmentIcon: {
      marginLeft: theme.spacing(1),
    },
    listPopover: {
      padding: theme.spacing(1),
    },
    age: {
      color: theme.palette.primary.darkRed,
    },
    communicationWrapper: {
      padding: theme.spacing(2),
    },
    phoneAvailableTimes: {
      fontWeight: 500,
    },
    adherenceButtonContainer: {
      paddingRight: theme.spacing(1),
    },
    adherenceButton: {
      minWidth: 'auto',
      padding: 0,
    },
    adherenceStatusTextGreen: {
      color: theme.palette.primary.cuttySark,
      fontSize: theme.spacing(1.625),
      fontWeight: '500',
    },
    adherenceStatusTextGrey: {
      color: theme.palette.primary.steelGrey,
      fontSize: theme.spacing(1.625),
      fontWeight: '500',
    },
    adherenceStatusTextRed: {
      color: theme.palette.primary.lightRed,
      fontSize: theme.spacing(1.625),
      fontWeight: '500',
    },
    additionalMrnsButtonContainer: {
      padding: '0 3px 0 0',
      justifyContent: 'flex-end',
    },
    additionalMrnsButton: {
      padding: 0,
      minWidth: 'auto',
    },
    multiMrnCopyToClipboardButton: {
      minHeight: 0,
      padding: '0 0 0 16px',
      float: 'right',
      minWidth: 'auto',
    },
    singleMrnCopyToClipboardButton: {
      minHeight: 0,
      padding: '0 0 0 8px',
      float: 'right',
      minWidth: 'auto',
    },
    mergeProfileButton: {
      marginLeft: theme.spacing(1.625),
    },
    // Modal styles
    genericModal: {
      position: 'absolute',
      width: theme.spacing(100),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    heading: {
      fontWeight: 'bold',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(2),
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    lastRow: {
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    error: {
      color: theme.palette.primary.error,
    },
    editWelcomeKitModal: {
      width: '500px',
    },
    // /Modal styles
  });
